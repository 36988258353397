import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { GET_TEST } from 'apollo/queries';
import './Score.css';

const componentInAnswer = (component, answer) => {

  let inAnswer = {
    ingredient: false,
    measurement: false,
    amount: false,
    component: null,
  }
  // eslint-disable-next-line
  answer.components.map((answered, i) => {
    if (component.ingredient.id === answered.ingredient.id) {
      inAnswer = {
        ingredient: true,
        measurement: component.measurement.name === answered.measurement.name,
        amount: component.amount === answered.amount,
        component: answered,
      }
    }
  })

  return inAnswer
}

export default function FillInTestScore({ route }) {

  const { loading, error, data } = useQuery(GET_TEST, {
    variables: {
      id: route?.match.params.id,
    },
    skip: !route?.match.params.id
  });

  const [currentAnswer, changeAnswer] = useState(0);

  if (!route) return null;
  if (loading || !data) {
    return (
      <Box className="flex flex-grow-1 flex-column justify-evenly items-center v-100">
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>Error {error}</div>
  }

  const { answers, questions } = data.getTest;
  const answer = answers[currentAnswer];
  const question = questions[currentAnswer];
  const technique = question.techniques[0] || { name: t('Missing Technique') }

  return (
    <Box className="Container WithHeader WithFooter FillInTestScore">
      <AppBar className="Header pt3" position="static">
        <Grid container className='items-center'>
          <Grid item xs={3}>
            <Typography align="center" sx={{ fontSize: 16 }}>
                {t('Answer')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align="center" sx={{ fontSize: 16 }}>
              {`${currentAnswer + 1 } / ${answers.length}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="center" sx={{ fontSize: 16 }}>
              {answer.title}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
      <Container className="Answer pt6">
        <h3 className='ma0'>{t('Basics')}</h3>
        <List className="Basics">
          <ListItem>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText
                    primary={t('Technique')}
                />
              </Grid>
              <Grid container className="items-center">
                <Grid item xs={4}>
                  <span className={answer.techniques[0].name === technique.name ? 'success' : 'error strike'}>{answer.techniques[0].name}</span>
                </Grid>
                <Grid item xs={4}>
                  { answer.techniques[0].name === technique.name ? '' : <span className="success">{technique.name }</span> }
                </Grid>
                <Grid item xs={4}>
                  { answer.techniques[0].name === technique.name ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" /> }
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText
                    primary={t('Glass Type')}
                />
              </Grid>
              <Grid container className="items-center">
                <Grid item xs={4}>
                  <span className={answer.glassType.name === question.glassType?.name ? 'success' : 'error strike'}>{answer.glassType.name}</span>
                </Grid>
                <Grid item xs={4}>
                  { answer.glassType.name === question.glassType?.name ? '' : <span className="success">{question.glassType?.name }</span> }
                </Grid>
                <Grid item xs={4}>
                  { answer.glassType.name === question.glassType?.name ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" /> }
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText
                    primary={t('Ice Type')}
                />
              </Grid>
              <Grid container className="items-center">
                <Grid item xs={4}>
                  <span className={answer.iceType === question.iceType ? 'success' : 'error strike'}>{answer.iceType}</span>
                </Grid>
                <Grid item xs={4}>
                  { answer.iceType === question.iceType ? '' : <span className="success">{question.iceType }</span> }
                </Grid>
                <Grid item xs={4}>
                  { answer.iceType === question.iceType ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" /> }
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </List>
        <h3 className='mb0'>{t('Components')}</h3>
        <List className="Components">
        { 
          question.components.map((component, i) => {
            
            const inAnswer = componentInAnswer(component, answer);
            if (!component.ingredient) {
              // this is invalid backend data
              console.error('Invalid Ingredient for Component:', component)
              return null
            }
            return (
              <React.Fragment key={`${component.ingredient.id}_${i}`}>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText 
                        primary={
                          <span className={inAnswer.ingredient ? 'success' : 'warning'}>{component.ingredient.name}</span>
                        }
                      />
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                          { inAnswer.ingredient && 
                            <React.Fragment>
                              <span className={inAnswer.amount && inAnswer.measurement ? 'success' : 'error strike mr4'}>{inAnswer.component.amount} {inAnswer.component.measurement.name}</span>
                            </React.Fragment>
                          }
                          { !inAnswer.ingredient && <span className={inAnswer.amount && inAnswer.measurement ? 'success' : 'warning'}>{component.amount} {component.measurement.name}</span> }
                        </Grid>
                        <Grid item xs={4}>
                         { (inAnswer.ingredient && (!inAnswer.amount || !inAnswer.measurement)) && <span className="success">{component.amount} {component.measurement.name}</span> }
                        </Grid>
                        <Grid item xs={4}>
                          { (inAnswer.ingredient && inAnswer.measurement && inAnswer.amount) ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" /> }
                        </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          })
        }
        </List>
      </Container>
      <Container id="fill-in-test-footer" className="Footer fixed" style={{ bottom: '3.5rem', background: 'white' }}>
        <Grid container>
          <Grid item xs={5}>
            <Button
              id="prev"
              fullWidth
              variant="outlined"
              disabled={ currentAnswer <= 0 }
              onClick={() => changeAnswer(currentAnswer - 1)}
              sx={{ mt: 2, mb: 2 }}
            >
              {t('PREVIOUS')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <Button
                id="next"
                fullWidth
                variant="outlined"
                disabled={ currentAnswer >= answers.length - 1 }
                onClick={() => changeAnswer(currentAnswer + 1)}
                sx={{ mt: 2, mb: 2 }}
            >
              {t('NEXT')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}