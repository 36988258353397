import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LOGOUT } from 'apollo/queries';
import getRoute, { login } from 'routes/nav';

export default function LogoutDialog({ opened, toggle, session }) {



  const [logout, { loading }] = useLazyQuery(LOGOUT, {
    onCompleted: (res) => window.location.href = getRoute(login),
    onError: (err) => console.log("error logging out", err),
  });

  const handleLogout = () => {
    logout({
      variables: {
        hash: session.refreshToken.hash,
        ip: session.refreshToken.ip,
        userAgent: session.refreshToken.userAgent,
      }
    })
  }
  
  const handleClose = () => {
    toggle(false)
  };

  return (
    <div>
      <Dialog
        open={opened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Close Session?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to close your active session?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose}>{t('CLOSE')}</LoadingButton>
          <LoadingButton onClick={handleLogout} loading={loading} autoFocus variant="outlined" color="error">
            {t('LOGOUT')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}