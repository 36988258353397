import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GET_INGREDIENTS } from 'apollo/queries'; 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ opened, onClose, onSave }) {

  const [open, setOpen] = useState(opened || false);
  const [selectedIngredient, setSelectedIngredient] = useState();
  const { loading, error, data } = useQuery(GET_INGREDIENTS);

  useEffect(() => {
    setOpen(opened || false)
  }, [opened])

  if (loading) return null;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  const ingredients = data.getIngredients.map(ingredient => ({ label: ingredient.name, value: ingredient.id }))
  const measurementsList = [
    "ml",
    "Dash",
    "Splash",
    "Drop",
    "Barspoon",
    "Tablespoon",
    "Teaspoon",
    "Pitch",
    "Hand",
    "gr",
  ]

  const handleAddComponent = (e) => {

    e.preventDefault();

    const { elements } = e.target;
    const amount = elements.componentAmount.value;
    const measurement = elements.componentMeasurement.value;
    
    onSave({
      amount: parseFloat(amount),
      measurement: {
        name: measurement
      },
      ingredient: {
        id: selectedIngredient.value,
        name: selectedIngredient.label,
      },
    })
  }

  return (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
      <form onSubmit={handleAddComponent}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('Add Ingredient')}
            </Typography>
            <Button autoFocus color="inherit" type="submit">
              {t('ADD')}
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <TextField
              id="componentAmount"
              type="number"
              label={t('Quantity')}
              step="0.01"
              className="w-100"
              required
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              disablePortal
              id="componentMeasurement"
              options={measurementsList.map((measurement) => ({ label: measurement }))}
              renderInput={(params) => <TextField required {...params} label="Measure" />}
              sx={{ flexGrow: 1 }}
              required
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              disablePortal
              id="componentIngredient"
              onChange={(event, element) => setSelectedIngredient(element) }
              options={ingredients}
              renderInput={(params) => <TextField required {...params} label="Ingredient" />}
              sx={{ flexGrow: 1 }}
              ListboxProps={{ style: { maxHeight: '7rem' } }}
              required
            />
          </ListItem>
        </List>
      </form>
    </Dialog>
  );
}