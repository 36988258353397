import React from 'react';
import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import Container from '@mui/material/Container';
import { UPDATE_PROFILE, PROFILE } from 'apollo/queries';
import { snackbarSuccess, snackbarError } from 'dispatcher/snackbar';



export default function EditEmail({ email, close }) {

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (e) => { snackbarSuccess(t('Email actualizado')) },
    onError: (e) => { snackbarError(t('Error actualizando el email')) }
  })

  const handleSave = async (e) => {
    e.preventDefault();
    const { elements } = e.target;

    await updateProfile({
      variables: {
        data: {
          email: elements.email.value
        },
      },
      refetchQueries: [{
        query: PROFILE
      }]
    })
    close()
  }
  

  return (
    <Container className="EditUsername">
      <form onSubmit={handleSave} autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <h5>{t('Escribe tu email')}</h5>
          </Grid>
          <Grid item xs={12} className="pb6">
            <FormControl variant="standard" className="w-100">
              <Input
                id="email"
                autoFocus
                defaultValue={email}
                type="email"
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid container className="pb4">
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <LoadingButton 
                color="error"
                onClick={() => close()}
              >
                {t('CANCELAR')}
              </LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <LoadingButton
                type="submit"
                loading={loading}
              >
                {t('GUARDAR')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}