import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import RecipeTechniqueSelector from 'components/RecipeTechniqueSelector';
import RecipeGlassTypeSelector from 'components/RecipeGlassTypeSelector';
import RecipeCategorySelector from 'components/RecipeCategorySelector';
import { GET_RECIPES_FILTERED, CREATE_TEST } from 'apollo/queries';
import getRoute, { fillInTest } from 'routes/nav';

export default function FillInTestFilters({ route }) {

  const [filters, updateFilters] = useState({
    techniques: "",
    glassType: "",
    category: "",
  })

  const { loading, data } = useQuery(GET_RECIPES_FILTERED, {
    variables: {
      filters: {
        ...(filters.techniques.length && { techniques: filters.techniques }),
        ...(filters.glassType.length && { glassType: filters.glassType }),
        ...(filters.category.length && { category: filters.category }),
      }
    }
  });

  const [createTest, { loading: creating }] = useMutation(CREATE_TEST, {
    onCompleted: ({ createTest }) => {
      route.history.push(getRoute(fillInTest, { params: { id: createTest.id }}))
    },
    onError: () => {
      console.error('Ha ocurrido un error')
    }
  });

  const handleUpdateFilters = (type) => {
    return (e) => {
      e.preventDefault()
      updateFilters({ ...filters, [type]: [e.target.value] })
    }
  }

  const handleCreateTest = () => {
    createTest({
      variables: {
        type: 'fill-in',
        filters: {
          ...(filters.techniques.length && { techniques: filters.techniques }),
          ...(filters.glassType.length && { glassType: filters.glassType }),
          ...(filters.category.length && { category: filters.category }),
        }
      }
    })
  }
  
  return (
    <Box className="Container WithHeader WithFooter h-100">
      <Container id="FillInTest Filters" className="pa6">
        <Grid container>
          <Grid item xs={12}>
            <RecipeCategorySelector
              value={filters.category}
              defaultValue={filters.category} 
              key="category"
              onChange={handleUpdateFilters('category')}
            />
            <RecipeTechniqueSelector
              key="filters.technique"
              onChange={handleUpdateFilters('techniques')}
            />
            <RecipeGlassTypeSelector
              key="glassType"
              onChange={handleUpdateFilters('glassType')}
            />
          </Grid>
        </Grid>
      </Container>
      <Container className="Footer fixed" style={{ bottom: '4.5rem', background: 'white' }}>
          <Grid item xs={12}>
            <LoadingButton
              loading={creating}
              className="w-100"
              variant="contained"
              disabled={ loading || data.getRecipes.items.length <= 0 }
              onClick={handleCreateTest}
            >
              { t('Start test with {{amount}} recipes', { amount: loading ? '--' : data.getRecipes?.items.length }) }
            </LoadingButton>
          </Grid>
      </Container>
    </Box>
  );
}