import * as React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DELETE_TEST, GET_MY_TESTS } from 'apollo/queries';

export default function LogoutDialog({ opened, toggle, test }) {

  const { enqueueSnackbar } = useSnackbar();

  const [deleteTest, { loading }] = useMutation(DELETE_TEST, {
    onCompleted: () => {
      enqueueSnackbar(t('Test deleted'), { variant: 'success' });
      toggle(false)
    },
    onError: (err) => enqueueSnackbar(t('Error deleting test'), { variant: 'error' }),
  });

  const handleLogout = () => {
    deleteTest({
      variables: {
        id: test.id
      },
      refetchQueries: [{
        query: GET_MY_TESTS
      }]
    })
  }
  
  const handleClose = () => {
    toggle({ opened: false, test: null })
  };

  return (
    <div>
      <Dialog
        open={opened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Test?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to delete this test?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose}>{t('CLOSE')}</LoadingButton>
          <LoadingButton onClick={handleLogout} loading={loading} autoFocus variant="outlined" color="error">
            {t('DELETE')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}