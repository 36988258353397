import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import { PROFILE } from 'apollo/queries';
import { getVersionInfo } from 'utils';
import * as serviceWorker from 'serviceWorker';
import EditUsername from './forms/EditUsername';
import EditEmail from './forms/EditEmail';
import EditPassword from './forms/EditPassword';
import LogoutDialog from './dialogs/Logout';
import VersionDialog from './dialogs/Version';
import { palette } from 'theme/config';
import './Profile.css';

export default function Profile({ session }) {

  const { loading, error, data } = useQuery(PROFILE);
  const [drawer, toggleDrawer] = useState({
    open: false,
    children: null
  })

  const [swUpdateAvailable, setSwUpdateAvailable] = useState(false)

  const [logoutDialogOpened, toggleLogOutDialog] = useState(false)
  const [versionDialogOpened, toggleVersionDialog] = useState(false)

  const closeDrawer = () => {
    return toggleDrawer({ open: false })
  }

  useEffect(() => {
    serviceWorker
      .updateAvailable()
      .then((isAvailable) => {
        setSwUpdateAvailable(isAvailable)
      })
  }, [swUpdateAvailable])
  


  if (loading) {
    return (
      <Box className="Loader flex flex-grow-1 justify-evenly items-center">
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    console.log('ERROR', error)
    return null;
  }

  const { profile } = data;

  return (
    <Box className="Container WithHeader WithFooter">
      <Container className="Profile flex justify-evenly items-center pa8">
        <Grid container>
          <Grid item xs={12}>
            <Avatar
              className="ma-auto"
              sx={{ width: 146, height: 146, bgcolor: palette.secondary.light }}
            >
              {profile.username.substr(0,1).toUpperCase()}
            </Avatar>
          </Grid>
        </Grid>
        <Grid container className="Username items-center pt8 pb4">
          <Grid item xs={2}>
            <PersonIcon />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className="ma0">{t('Username')}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{profile.username}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="edit"
              onClick={(e) => {
                toggleDrawer({
                  open: true,
                  children: <EditUsername username={profile.username} close={closeDrawer} />
                })
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2}/>
          <Grid className="disclaimer pt4" item xs={9}>
            <Typography variant="body2">{t('This is your unique username')}</Typography>
            <Typography variant="body2">{t('This username will be visible on the Mixole community')}</Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Divider />
        <Grid container className="Email items-center pt4 pb4">
          <Grid item xs={2}>
            <EmailIcon />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className="ma0">{t('Email')}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{profile.email}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="edit"
              onClick={(e) => {
                toggleDrawer({
                  open: true,
                  children: <EditEmail email={profile.email} close={closeDrawer} />
                })
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className="Password items-center pt4 pb4">
          <Grid item xs={2}>
            <KeyIcon />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className="ma0">{t('Password')}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>*******</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="edit"
              onClick={(e) => {
                toggleDrawer({
                  open: true,
                  children: <EditPassword close={closeDrawer} />
                })
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className="Logout items-center pt4 pb4" onClick={() => toggleLogOutDialog(true)}>
          <Grid item xs={2}>
            <LogoutIcon />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" className="ma0">{t('Logout')}</Typography>
            <Grid className="disclaimer pt4" item xs={9}>
              <Typography variant="body2">{t('Closes you active session on this device')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className="Info items-center pt4 pb4" onClick={() => {toggleVersionDialog(true)}}>
          <Grid item xs={2}>
            <InfoIcon />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1" className="ma0">{t('Version')}</Typography>
            <Typography variant="caption">{ swUpdateAvailable ? t('New Version Available!') : '' }</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{getVersionInfo().version}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Drawer
          anchor='bottom'
          open={drawer.open}
          onClose={() => { toggleDrawer({ ...drawer, open: false }) }}
        >
        {drawer.children}
        </Drawer>
        <LogoutDialog opened={logoutDialogOpened} toggle={toggleLogOutDialog} session={session} />
        <VersionDialog opened={versionDialogOpened} toggle={toggleVersionDialog} swUpdateAvailable={swUpdateAvailable} />
      </Container>
    </Box>
  )
}
