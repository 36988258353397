import { SET_SCROLL } from 'redux/actions';

export function scroll (state = {}, action) {
  switch (action.type) {
    case SET_SCROLL:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default: return state
  }
}
