import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import InputSearch from 'components/Input/Search';
import Filter from './Filter';

export default function RecipesHeader({ search, filters }) {


  const [filterDialogOpened, toggleFilterDialog] = useState(false);
 

  if (!search) return null

  const activeFilters = () => {
    return Object.keys(filters.value).map(key => filters[key] !== '').indexOf(true) > -1
  } 

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
        <InputSearch defaultValue={search.value} searchAction={search.searchAction} clearAction={search.clearAction} />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          color="primary" 
          sx={{ p: '10px' }} 
          aria-label="directions" 
          onClick={e => toggleFilterDialog(true)}>
          <TuneIcon color={activeFilters(filters) ? 'secondary' : 'primary'} />
        </IconButton>
      </Paper>
      <Filter
        defaultValue={filters.value}
        opened={filterDialogOpened}
        onClose={() => {
          toggleFilterDialog(false)
        }}
        onClear={() => {
          toggleFilterDialog(false)
          filters.filterAction(null)
        }}
        onSave={(value) => {
          toggleFilterDialog(false)
          filters.filterAction(value)
        }}
      />
    </React.Fragment>
  );
}