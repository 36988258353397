import React from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function WithServiceWorker ({ serviceWorker }) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showUpdateSnackbar = () => {
    setTimeout(() => {
      enqueueSnackbar(t('Update Available!'), { 
        variant: 'info',
        style: { marginBottom: '3rem' },
        persist: true,
        action: (key) => (
          <div>
            <Button onClick={() => closeSnackbar(key)}>
              {t('DISMISS')}
            </Button>
            <IconButton
                onClick={() => serviceWorker.update()}
                style={{ fontSize: '20px' }}
            >
                <AutorenewIcon />
            </IconButton>
          </div>
        )
      })
    }, 1000)
  }

  serviceWorker
    .updateAvailable()
    .then(isAvailable => {
      if (isAvailable) showUpdateSnackbar()
    })

  return null
}
