
import { withEffects } from 'redux/refractSideEffects';
import { pipe, map } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { onRouteChange } from 'pipes/route';
import Tests from './Tests';

const aperture = () => component =>
  pipesToProps(
    pipe(
      onRouteChange(component),
      map(route => ({ route })),
    )
  )

const handler = () => effect => {
  switch(effect.type) {
    default: return null
  }
}

export default withEffects(handler)(aperture)(Tests);
