import React from 'react';
import { useQuery } from '@apollo/client';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { GET_GLASS_TYPES } from 'apollo/queries';


export default function RecipeGlassTypeSelector ({ defaultValue, onChange, required }) {

  const { loading, error, data } = useQuery(GET_GLASS_TYPES);
  if (loading) return null;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getGlassTypes: types } = data;

  return (
    <FormControl sx={{ my: 1, width: '100%' }} required={required}>
      <InputLabel id="glass-type-selector-label">Glass Type</InputLabel>
      <NativeSelect
        id="glass-type-selector"
        defaultValue={defaultValue}
        onChange={onChange}
        input={<OutlinedInput type="select" required={required} label="Select Glass Type" />}
      >
        <option hidden value=""></option>
        {types.map(({ name, id }) => (
          <option
            key={id}
            value={id}
          >
            {name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}