import { reduxStorageLocalSet, reduxStorageSessionSet, reduxStorageRemove } from 'redux/storage'
import { DISTINCT_ID, TOKEN, IMPERSONATE, PROFILE, SESSION, LOGOUT } from 'redux/actions';
import { LocalStorage } from 'utils';

const clearUser = () => {
  LocalStorage.remove('rememberSession');
  reduxStorageRemove('user');
}

const persistUser = (user) => {
  const rememberSession  = LocalStorage.get('rememberSession');

  if (rememberSession) {
    reduxStorageLocalSet('user', user);
  } else {
    reduxStorageSessionSet('user', user);
  }

  return user;
}

export function user(state = {}, action) {
  switch (action.type) {
    case DISTINCT_ID:
      reduxStorageLocalSet('user', { distinct_id: action.payload.distinct_id });
      return Object.assign({}, state, {
        distinct_id: action.payload.distinct_id,
      });
    case TOKEN:
      return Object.assign({}, state, {
        token: action.payload.token,
      });
    case IMPERSONATE:
      return Object.assign({}, state, {
        impersonate: action.payload.impersonate,
        impersonate_type: action.payload.impersonate_type,
      });
    case PROFILE:
      const { profile } = action.payload;
      return persistUser(Object.assign({}, state, {
        profile: profile,
      }));
    case SESSION:
      const { session } = action.payload;
      const sessionWithExpiration = !!session && !!session.ttl ? Object.assign({}, session, { expiration: new Date().getTime() + session.ttl * 1000 }) : null;
      return persistUser(Object.assign({}, state, {
        session: sessionWithExpiration
      }));
    case LOGOUT:
      clearUser();

      return Object.assign({}, state, {});
    default: return state
  }
}
