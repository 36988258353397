import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function RecipeIceTypeSelector ({ defaultValue, onChange }) {

  const iceTypes = [
    "Cubed",
    "Crushed",
    "None",
  ];
  
  return (
    <FormControl sx={{ my: 1, width: '100%' }} required>
      <InputLabel id="ice-type-selector-label">Ice Type</InputLabel>
      <NativeSelect
        id="ice-type-selector"
        defaultValue={defaultValue}
        onChange={onChange}
        input={<OutlinedInput label="Ice Type" />}
      >
        <option hidden value=""></option>
        {iceTypes.map((name) => (
          <option
            key={name}
            value={name}
          >
            {name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}