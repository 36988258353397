import React from 'react';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CREATE_TEST } from 'apollo/queries/tests';
import getRoute, { fillInTest, fillInTestFilters } from 'routes/nav';



export default function SelectionScreen({ route }) {

  const [createTest] = useMutation(CREATE_TEST, {
    onCompleted: ({ createTest }) => {
      route.history.push(getRoute(fillInTest, { params: { id: createTest.id }}))
    },
    onError: () => {
      console.error('Ha ocurrido un error')
    }
  });

  return (
    <Box className="flex flex-grow-1 flex-column justify-evenly items-center" sx={{ height: 'calc(100% - 112px)'}}>
      <Stack direction="column" spacing={8} sx={{ padding: 2 }}>
        <Card elevation={5}>
          <CardActionArea
            onClick={() => setTimeout(() => createTest({
              variables: {
                type: 'fill-in'
              }
            }))}
          >
            <CardContent>
              <Stack direction="row" spacing={2}>
                <AllInclusiveIcon /><Typography variant="h5"> {t('Endless Mode')} </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary">
                  {t('Start right away with random recipes. You can stop whenever you want')}
                </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card elevation={5}>
          <CardActionArea
            onClick={() => route.history.push(getRoute(fillInTestFilters)) }
          >
            <CardContent>
              <Stack direction="row" spacing={2}>
                <FilterAltIcon /><Typography variant="h5"> {t('Custom Filter')} </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary">
                  {t('Filter the recipe DB to narrow down the scope of what you want')}
                </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    </Box>
  );
}