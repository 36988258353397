import React from 'react';
import { hydrate, render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import './css/tachyons.scss';
import './css/overrides/classes.scss';
import './css/overrides/colors.scss';
import App from 'components/App';
import { client as ApolloClient } from 'apollo';
import SetLayout from 'hocs/SetLayout';
import WithServiceWorker from 'hocs/WithServiceWorker';
import { palette } from './theme/config'


const rootElement = document.getElementById('root');

const AppWithLayout = SetLayout(App);

const theme = createTheme({
  palette,
});

const ContextApp = () => {
  return (
    <React.Fragment>
      <ApolloProvider client={ApolloClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3} preventDuplicate classes={{ variantInfo: 'customInfo' }}>
            <AppWithLayout />
            <WithServiceWorker serviceWorker={serviceWorker} />
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </React.Fragment>
  )
}

if (rootElement.hasChildNodes()) {
  hydrate(<ContextApp />, rootElement);
} else {
  render(<ContextApp />, rootElement);
}

serviceWorker.register();
