import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function SnackBar({ type, children, action, close }) {
  if (!children) return null;

  const actionIcon = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  if (!type) {
    return (
      <Snackbar
        open={true}
        onClose={close}
        action={actionIcon}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={children}
        sx={{ 'paddingBottom': '4rem' }}
      />
    )
  }

  return (
    <Snackbar
      open={true}
      onClose={close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ 'paddingBottom': '4rem' }}
    >
      <Alert className="w-100" severity={type}>
        { children || t(`This is a ${type} message!`) }
      </Alert>
    </Snackbar>
  )
}