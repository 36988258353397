export const palette = {
  mode: "light",
  primary: {
    main: '#233142',
    light: '#354a63',
    dark: '#111821',
  },
  secondary: {
    main: '#CBB26B',
    light: '#d8c691',
    dark: '#be9e45',
    contrastText: '#111821',
  },
   // Provide every color token (light, main, dark, and contrastText) when using
   // custom colors for props in Material UI's components.
   // Then you will be able to use it like this: `<Button color="custom">`
   // (For TypeScript, you need to add module augmentation for the `custom` value)
  custom: {
    light: '#ffa726',
    main: '#f57c00',
    dark: '#ef6c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  background: {
    default: '#354a63'
  }
}