const [branch, hash, version] = process.env.REACT_APP_RELEASE.split(':');
const checksum = process.env.REACT_APP_DEPLOY_HASH;

export const getVersionInfo = () => {
  return {
    branch,
    hash,
    version,
    checksum,
  }
}