import { ApolloLink } from '@apollo/client';
import { client as apolloClient } from 'apollo';
import store from 'redux/store';
import { 
  session as setSession,
  profile as setProfile,
  logout,
} from 'redux/actions';
import { LOGIN, PROFILE, LOGOUT } from 'apollo/queries';


const getProfileOnLogin = new ApolloLink((operation, forward) => {

  const loginOperation = LOGIN.definitions[0].name.value;
  const isLoginOperation =  operation.operationName === loginOperation;

  if (!isLoginOperation) return forward(operation);

  return forward(operation).map((response) => {

    const { data } = response;

    if (response.errors?.length) {
      return response;
    }

    store.dispatch(setSession({ session: data.login }));
    apolloClient.query({ query: PROFILE })

    return response;
  })
});

const updateProfile = new ApolloLink((operation, forward) => {

  const profileOperation = PROFILE.definitions[0].name.value;
  const isProfileOperation =  operation.operationName === profileOperation;

  if (!isProfileOperation) return forward(operation);

  return forward(operation).map((response) => {

    const { data } = response;
    
    if (response.errors?.length) {
      return response;
    }
    
    store.dispatch(setProfile({ profile: data.profile }));

    return response;
  })
});

const clearSessionOnLogout = new ApolloLink((operation, forward) => {

  const logoutOperation = LOGOUT.definitions[0].name.value;
  const isLogoutOperation =  operation.operationName === logoutOperation;

  if (!isLogoutOperation) return forward(operation);

  return forward(operation).map((response) => {

    store.dispatch(logout());
    return response;
  })
});

export default ApolloLink.from([getProfileOnLogin, updateProfile, clearSessionOnLogout]);