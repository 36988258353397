
import { withEffects } from 'redux/refractSideEffects';
import { pipe, map, merge, filter } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { onRouteChange } from 'pipes/route';
import { SESSION } from 'redux/actions';
import getRoute, { tests } from 'routes/nav';
import Homepage from './Homepage';

const aperture = ({ store }) => component => {
  return merge(
    pipesToProps(
      pipe(
        onRouteChange(component),
        map(route => ({ route })),
      )
    ),
    pipe(
      merge(component.mount, store.observe(SESSION)),
      map(() => store.getState().user?.session),
      filter(session => !!session),
      map(() => ({ type: 'IS_LOGGED' }))
    )
  )
}

const handler = ({ history }) => effect => {
  switch(effect.type) {
    case 'IS_LOGGED':
      history.replace(getRoute(tests));
      return;
    default:
      return;
  }
}

export default withEffects(handler)(aperture)(Homepage);
