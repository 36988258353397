import { withEffects, toProps } from 'redux/refractSideEffects';
import { map, forEach, wait, merge, pipe } from 'callbag';
import { snackerKill } from 'dispatcher/snackbar'
import SnackBar from './snackbar';

const aperture = props => component => {
  const [closing$, close] = component.useEvent('CLOSE');

  return merge(
    pipe(
      component.mount,
      map(() => toProps({ close }))
    ),
    pipe(
      closing$,
      map(x => toProps({ closing: true }))
    ),
    pipe(
      component.mount,
      wait(props.timeout || 4500),
      forEach(close)
    ),
    pipe(
      closing$,
      wait(500),
      forEach(() => snackerKill(props.id))
    ),
  );
}

const handler = () => () => null;

const SnackBarEffects = withEffects(handler)(aperture)(SnackBar, 'SnackBar');
export default SnackBarEffects;
