import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import RecipeTechniqueSelector from 'components/RecipeTechniqueSelector';
import RecipeGlassTypeSelector from 'components/RecipeGlassTypeSelector';
import RecipeCategorySelector from 'components/RecipeCategorySelector';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ opened, onClose, onSave, onClear, defaultValue }) {

  const [open, setOpen] = useState(opened || false);
  const [filters, updateFilters] = useState(defaultValue)

  useEffect(() => {
    setOpen(opened || false)
  }, [opened])


  const handleUpdateFilters = (type) => {
    return (e) => {
      e.preventDefault()
      updateFilters({ ...filters, [type]: [e.target.value] })
    }
  }

  return (
    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('Filter Recipes')}
          </Typography>
          <Button autoFocus color="inherit" onClick={onClear}>
            {t('RESET')}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container id="FillInTest Filters" className="h-100 pa6">
        <Grid item xs={12}>
          <RecipeCategorySelector
            defaultValue={filters.category ? filters.category[0]: ''} 
            key="category"
            onChange={handleUpdateFilters('category')}
          />
          <RecipeTechniqueSelector
            defaultValue={filters.techniques ? filters.techniques[0] : ''}
            key="filters.technique"
            onChange={handleUpdateFilters('techniques')}
          />
          <RecipeGlassTypeSelector
            defaultValue={filters.glassType ? filters.glassType[0] : ''}
            key="glassType"
            onChange={handleUpdateFilters('glassType')}
          />
        </Grid>
      </Grid>
      <Container className="Footer fixed" style={{ bottom: '4.5rem', background: 'white' }}>
        <Grid item xs={12}>
          <Button
            className="w-100"
            variant="contained"
            onClick={() => onSave(filters)}
          >
            { t('APPLY') }
          </Button>
        </Grid>
      </Container>
    </Dialog>
  );
}