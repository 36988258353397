import { pushEvent } from 'dispatcher'

const newSnackBar = pushEvent('SNACKBAR_NEW')

export const snackbar = props =>
  newSnackBar({ children: props })

export const snackbarSuccess = props =>
  newSnackBar({ children: props, type: 'success' })

export const snackbarError = props =>
  newSnackBar({ children: props, type: 'error' })

export const snackbarWarning = props =>
  newSnackBar({ children: props, type: 'warning' })

  export const snackbarInfo = props =>
  newSnackBar({ children: props, type: 'info' })

const killSnackBar = pushEvent('SNACKBAR_KILL')

export const snackerKill = id =>
  killSnackBar(id)