import * as React from 'react';
import queryString from 'query-string'
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import getRoute, { fillInTest } from 'routes/nav';
import MyResults from './MyResults';
import './Tests.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function Tests({ route }) {
  
  const { tab } = queryString.parse(route?.location.search);
  const theme = useTheme();
  const [value, setValue] = React.useState(tab || 0);

  const handleChange = (event, newValue) => {
    handleChangeIndex(newValue)
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    route.history.push({
      search: `?tab=${index}`,
    })
  };

  if (!route) return null;
  if (!!tab && parseInt(tab) !== value) {
    setValue(parseInt(tab))
  }

  return (
    <React.Fragment>
      <Box className="Container WithHeader WithFooter h-100">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t('Take a test')} {...a11yProps(0)} />
            <Tab label={t('My results')} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          style={{flex: 1, height: '100%' }}
          containerStyle={{ height: '100%', paddingBottom: '3.5rem' }}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel className="h-100" value={value} index={0} dir={theme.direction}>
            <Box className="flex flex-grow-1 flex-column justify-evenly items-center h-100">
              <Card sx={{width: '80%'}} elevation={5}>
                <CardActionArea
                  onClick={() => setTimeout(() => route.history.push(getRoute(fillInTest)), 300) }
                >
                  <CardContent>
                    <Typography variant="h5" component="div" sx={{ mb: 1.5 }} color="text.secondary">
                      {t('Fill In Recipe')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t('Prove your mixologist knowledge by completing cocktail recipes from scratch')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography variant="body2" color="text.primary" className="b pa3">
                      {t('LET\'S START!')}
                    </Typography>
                  </CardActions>
                </CardActionArea>
              </Card>
              <Card sx={{ width: '80%', bgcolor: 'text.disabled' }} elevation={5}>
                <CardActionArea disabled>
                  <CardContent disabled>
                    <Typography variant="h5" component="div" sx={{ mb: 1.5, display: 'flex' }} color="text.secondary">
                      {t('Multiple Choice')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t('Get memory to work with these easy to learn questions about cockatil recipes')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Typography variant="body2" color="text.secondary" className="b pa3">
                      {t('COMING SOON!')}
                    </Typography>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction} className="h-100">
            <MyResults route={route} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </React.Fragment>
  )
}
