import { withEffects } from 'redux/refractSideEffects';
import { merge, pipe, map } from 'callbag';
import { onRouteChange } from 'pipes/route';
import { pipesToProps } from 'pipes/toProps';
import { session as setSession } from 'redux/actions';
import Invite from './Invite';

const aperture = ({ store }) => component => {
  const dispatchSession = ({ session }) => {
    store.dispatch(setSession({ session }))
  }

  return merge(
    pipesToProps(
      pipe(
        onRouteChange(component),
        map(route => ({ route })),
      ),
      pipe(
        component.mount,
        map(() => ({
          dispatchSession,
        }))
      ),
    ),
    pipe(component.mount, map(() => ({type: 'MOUNT'}))),
  )
}

const handler = ({ store, route, match, history }) => effect => {
  switch (effect.type) {
    case 'MOUNT':
      break;
    default:
      return;
  }
}

export default withEffects(handler)(aperture)(Invite, 'InviteContainer');
