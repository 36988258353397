import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SearchIcon from '@mui/icons-material/Search';
import QuizIcon from '@mui/icons-material/Quiz';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';
import getRoute, { tests, profile, recipes } from 'routes/nav';

const isActivePath = (route, paths) => {
  return JSON.stringify(route.paths) === JSON.stringify(paths) || (!!route.parentRoute && JSON.stringify(route.parentRoute?.paths) === JSON.stringify(paths))
}

export default ({ route, history }) => {
  const [value, setValue] = React.useState(route);
  if(!route) return null
  if (route && route.hideFooter) return null

  return (
    <Paper id="app-footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <Box sx={{ width: '100%' }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            if(newValue.paths) route.history.replace(getRoute(newValue))
            setValue(newValue);
          }}
        >
          <BottomNavigationAction value={recipes} className={route && isActivePath(route, recipes.paths) ? `Mui-selected` : ''} label={t('Discover')} icon={<SearchIcon />} />
          <BottomNavigationAction value={tests} className={route && isActivePath(route, tests.paths) ? `Mui-selected` : ''} label={t('Tests')} icon={<QuizIcon />} />
          <BottomNavigationAction value={profile} className={route && isActivePath(route, profile.paths) ? `Mui-selected` : ''} label={t('Profile')} icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </Box>
    </Paper>
  );
}
