import { gql } from '@apollo/client';

export const LOGIN = gql`
  query LOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      type
      accessToken
      refreshToken {
        hash
        ip
        userAgent
      }
      ttl
    }
  }
`;

export const LOGIN_REFRESH = gql`
  query LOGIN_REFRESH($hash: String!) {
    loginRefresh(hash: $hash) {
      type
      accessToken
      refreshToken {
        hash
        ip
        userAgent
      }
      ttl
    }
  }
`;

export const LOGOUT = gql`
  query LOGOUT($hash: String!, $ip: String!, $userAgent: String!) {
    logout(hash: $hash, ip: $ip, userAgent: $userAgent)
  }
`;

export const PROFILE = gql`
  query PROFILE {
    profile {
      username,
      id,
      uuid,
      email,
      active,
      roles {
        name
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($data: UpdateProfileInput!) {
    updateProfile(data: $data) {
      id
      uuid
      email
      username
      roles {
        name
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data)
  }
`;

export const GET_USERS = gql`
  query {
    users {
      id
      active
      username
      email
      roles {
        id
        name
      }
      createdAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      id
      active
      email
      username
      roles {
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const CREATE_USER = gql`
  mutation createUser($email: String!, $password: String!, $username: String) {
    createUser(email: $email, password: $password, username: $username) {
      id
      active
      username
      email
    }
  }
`;

export const CREATE_USER_SUBSCRIPTION = gql`
  subscription {
    createdUser {
      id
      active
      username
      email
    }
  }
`;

export const DELETE_USER_SUBSCRIPTION = gql`
  subscription {
    deletedUser {
      id
    }
  }
`;