//eslint-disable-next-line
const b = a => a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b);
export const uuid = b;

export const nestedProp = (obj, path) => path.reduce((o, i) => (o && o[i] !== 'undefined') ? o[i] : undefined, obj);

export const topDomain = window.top === window.self? document.domain : document.referrer.match(/^https?:\/\/([^:/?]*)/i)[1]

export const isDevEnvironment = process.env.REACT_APP_NODE_ENV === 'development' || process.env.NODE_ENV !== 'production'

export const flatten = arr => arr.reduce((flat, next) => flat.concat(next), []);

export { default as Mixpanel } from './Mixpanel';

export { capitalize } from './string';

export { camelize } from './string';

export { default as validation } from './formValidation';

export { filterAttributeProps } from './props';

export * from './storage';

export * from './navigation';

export * from './config';

export * from './crypto';

export * from './urls';

export * from './objects';

export * from './version';