
import { pipe, map, merge } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { withEffects } from 'redux/refractSideEffects';
import { SESSION } from 'redux/actions';
import Profile from './Profile';

const aperture = ({ store }) => component => {
  return pipesToProps(
      pipe(
      merge(component.mount, store.observe(SESSION)),
      map(() => store.getState().user?.session),
      map((session) => ({ session }))
    )
  )
}
const handler = () => effect => {
  switch(effect.type) {
    default: return null
  }
}

export default withEffects(handler)(aperture)(Profile);
