import React, { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

let activeTimeout = null;

export default function InputSearch ({ 
  defaultValue, 
  searchAction = () => null,
  clearAction = () => null,
  actionDelayOnChange = 500,
  actionMinValueLength = 3 
}) {

  const [inputDirty, setInputDirty] = useState(!!defaultValue || false)

  const handleSearch = ({ target, searchAction, actionDelayOnChange, actionMinValueLength }) => {
    const { value } = target
    if (value.length > 0 && value.length < actionMinValueLength) {
      return
    }
    if (activeTimeout) {
      clearTimeout(activeTimeout);
    }
    activeTimeout = setTimeout(() => {
      searchAction({ value: value || null })
    }, actionDelayOnChange)
  }
  
  const clearSearch = (e) => {
    e.target.value = ''
    clearAction()
    setInputDirty(false)
  }
  

  return (
    <React.Fragment>
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search recipes"
        defaultValue={defaultValue}
        inputProps={{ 'aria-label': 'search-recipes' }}
        onChange={(e) => {
          setInputDirty(e.target.value.length > 0);
          handleSearch({ target: e.target, searchAction, actionDelayOnChange, actionMinValueLength });
        }}
        endAdornment={
          inputDirty && <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={clearSearch}>
              <ClearIcon />
          </IconButton>
        }
      />
    </React.Fragment>
  )
}