import { gql } from '@apollo/client';

export const ACCEPT_INVITE = gql`
  mutation acceptInvite($teamId: ID!, $inviteHash: String!, $data: AcceptInviteInput) {
    acceptInvite(teamId: $teamId, inviteHash: $inviteHash, data: $data) {
      type
      accessToken
      refreshToken {
        hash
        ip
        userAgent
      }
      ttl
    }
  }
`;