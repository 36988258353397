import { gql } from '@apollo/client';

export const CREATE_TEST = gql`
  mutation createTest($type: String!, $filters: TestFilterInput) {
    createTest(type: $type, filters: $filters) {
      id
    }
  }
`;

export const GET_TEST = gql`
  query getTest($id: ID!) {
    getTest(id: $id) {
      id
      type
      length
      score
      endedAt
      questions {
        title
        category {
          id
          name
        }
        type {
          id
          name
        }
        techniques {
          id
          name
        }
        glassType {
          id
          name
        }
        iceType
        components {
          ingredient {
            id
            name
          }
          amount
          measurement {
            name
          }
        }
      },
      answers {
        title
        category {
          id
          name
        }
        type {
          id
          name
        }
        techniques {
          id
          name
        }
        glassType {
          id
          name
        }
        iceType
        components {
          ingredient {
            id
            name
          }
          amount
          measurement {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_TEST = gql`
  mutation updateTest($id: ID!, $type: String!, $data: AnswerInput!) {
    updateTest(id: $id, type: $type, data: $data) {
      id
      length
    }
  }
`;

export const DELETE_TEST = gql`
  mutation deleteTest($id: ID!) {
    deleteTest(id: $id)
  }
`;

export const END_TEST = gql`
  mutation endTest($id: ID!, $type: String!, $data: AnswerInput!) {
    endTest(id: $id, type: $type, data: $data) {
      id
      type
      length
      questions {
        title
        category {
          name
        }
      },
      answers {
        components {
          ingredient {
            name
          }
        }
      }
    }
  }
`;

export const GET_MY_TESTS = gql`
  query getMyTests {
    getMyTests {
      id,
      type,
      score,
      createdAt,
      endedAt
    }
  }
`