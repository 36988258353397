import React from 'react';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { GET_RECIPE } from 'apollo/queries';
import emptyState from 'utils/emptyState';
import './Recipe.css';


export default function Recipe({ route }) {
  
  const { loading, data } = useQuery(GET_RECIPE, {
    variables: {
      id: route?.match.params.id
    },
    skip: !route || !route?.match.params.id
  })

  if (!route) return null
  if (loading || !data) {
    return (
      <Box className="flex flex-grow-1 flex-column justify-evenly items-center v-100">
        <CircularProgress />
      </Box>
    )
  }

  const recipe = data.getRecipe;
  
  return (
    <Container className="Recipe">
      <Card sx={{ borderRadius: '0px' }}>
        <CardMedia
          component="img"
          height="180"
          image={recipe.cover?.url || emptyState()}
          alt={recipe.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            {recipe.title}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {`${recipe.team ? `by ${recipe.team.name}` : ''}`}
          </Typography>
          <Chip label={recipe.category ? recipe.category.name : t('Uncategorized' ())} variant="outlined" className="mr2 mb2"/>
          <Chip label={recipe.techniques[0]?.name || t('Uncategorized')} variant="outlined" className="mr2 mb2" />
          { recipe.glassType && <Chip label={recipe.glassType.name} variant="outlined" className="mr2 mb2"/> }
        </CardContent>
        { recipe.components && recipe.components.length > 0 && 
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {t('Components')}
            </Typography>
            <List>
              { 
                recipe.components.map((component) => {
                  return (
                    <ListItem key={component.ingredient.id}>
                      <Typography component="p" className="w-100">
                        {component.ingredient.name}
                      </Typography>
                      <Typography component="span" sx={{ marginRight: '.25rem' }}>
                        {component.amount}
                      </Typography>
                      <Typography component="span" className="nowrap">
                        {component.measurement.name}
                      </Typography>
                    </ListItem>
                  )
                })
              }
            </List>
          </CardContent>
        }
        { recipe.decoration && recipe.decoration.ingredients.length > 0 && 
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {t('Decoration')}
            </Typography>
            <List>
              { 
                recipe.decoration.ingredients.map((component) => {
                  if (!component.ingredient) return null
                  return (
                    <ListItem key={component.ingredient.id}>
                      <Typography component="p" className="w-100">
                        {component.ingredient.name}
                      </Typography>
                    </ListItem>
                  )
                })
              }
            </List>
            { recipe.decoration.description && 
              <Typography gutterBottom variant="body2" component="p">
                {recipe.decoration.description}
              </Typography>
            }
          </CardContent>
        }
        { recipe.elaboration && recipe.elaboration.length > 0 && 
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {t('Elaboration')}
            </Typography>
            <List>
              { 
                recipe.elaboration.map((step, i) => {
                  return (
                    <ListItem key={i}>
                      <Typography component="p" className="w-100">
                        {step.description}
                      </Typography>
                    </ListItem>
                  )
                })
              }
            </List>
          </CardContent>
        }
        <Divider />
        { recipe.history && 
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {t('History')}
            </Typography>
            {
              recipe.history.split('.\n').map((paragraph, i) => {
                return (
                  <Typography key={i} variant="body2" component="p" className="pb4">
                    {paragraph}
                  </Typography>
                )
              })
            }
          </CardContent>
        }
        <Divider />
      </Card>
    </Container>
  )
}