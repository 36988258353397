import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function InputPassword({ label, defaultValue, ...props }) {

  const [visible, showPassword] = useState(false);

  const handleClickShowPassword = () => {
    showPassword(!visible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <React.Fragment>
      <TextField
        {...props}
        label={label}
        variant="standard"
        type={visible ? 'text' : 'password'}
        defaultValue={defaultValue}
        InputProps={{
          endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              { visible ? <VisibilityOff /> : <Visibility /> }
            </IconButton>
          </InputAdornment>
        }}
      />
    </React.Fragment>
  )
}