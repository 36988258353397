import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { palette } from 'theme/config';

function getRandomArbitrary(min, max) {
  return parseInt(Math.random() * (max - min) + min);
}

const PATHS = [
  "M -740.5034692842462,56 C -644.5,153.4 -452.5,525.2 -260.50346928424625,543 C -68.5,560.8 27.5,190.8 219.49653071575375,145 C 411.5,99.2 507.5,313.8 699.4965307157538,314 C 891.5,314.2 1031.4,148.6 1179.4965307157538,146 C 1327.6,143.4 1387.9,270 1440,301",
  "M -857.5295459966161,331 C -761.53,296.4 -569.53,159.6 -377.5295459966161,158 C -185.53,156.4 -89.53,311.8 102.4704540033839,323 C 294.47,334.2 390.47,203 582.4704540033839,214 C 774.47,225 890.96,373.8 1062.470454003384,378 C 1233.98,382.2 1364.49,263.6 1440,235",
  "M -326.0552981113824,156 C -230.06,218.4 -38.06,494.4 153.94470188861757,468 C 345.94,441.6 441.94,16.6 633.9447018886176,24 C 825.94,31.4 921.94,506.4 1113.9447018886176,505 C 1305.94,503.6 1528.73,16.8 1593.9447018886176,17 C 1659.16,17.2 1470.79,408.2 1440,506",
  "M -745.563549215282,533 C -649.56,443.2 -457.56,101.2 -265.563549215282,84 C -73.56,66.8 22.44,459.8 214.43645078471798,447 C 406.44,434.2 502.44,51.8 694.436450784718,20 C 886.44,-11.8 1025.32,286.8 1174.436450784718,288 C 1323.55,289.2 1386.89,78.4 1440,26",
  "M -277.98220375655944,190 C -181.98,223.8 10.02,358.6 202.01779624344053,359 C 394.02,359.4 490.02,180.8 682.0177962434406,192 C 874.02,203.2 970.02,441 1162.0177962434404,415 C 1354.02,389 1586.42,40.2 1642.0177962434404,62 C 1697.61,83.8 1480.4,431.6 1440,524",
  "M -352.776486358106,270 C -256.78,291.4 -64.78,377.2 127.223513641894,377 C 319.22,376.8 415.22,266.2 607.223513641894,269 C 799.22,271.8 895.22,432 1087.223513641894,391 C 1279.22,350 1496.67,54.6 1567.223513641894,64 C 1637.78,73.4 1465.44,363.2 1440,438",
  "M -458.7382784626245,456 C -362.74,374 -170.74,80.8 21.261721537375458,46 C 213.26,11.2 309.26,284.4 501.2617215373755,282 C 693.26,279.6 789.26,8.6 981.2617215373755,34 C 1173.26,59.4 1369.51,379.8 1461.2617215373755,409 C 1553.01,438.2 1444.25,225.8 1440,180",
  "M -954.0747606202704,55 C -858.07,137.6 -666.07,472.6 -474.07476062027035,468 C -282.07,463.4 -186.07,29.6 5.925239379729646,32 C 197.93,34.4 293.93,441.6 485.92523937972965,480 C 677.93,518.4 775.11,247 965.9252393797296,224 C 1156.74,201 1345.19,336.8 1440,365",
  "M -227.62665918760462,330 C -170.03,319.2 -54.83,263.6 60.37334081239537,276 C 175.57,288.4 233.17,430.4 348.3733408123954,392 C 463.57,353.6 521.17,84.4 636.3733408123953,84 C 751.57,83.6 809.17,366.4 924.3733408123953,390 C 1039.57,413.6 1097.17,182.4 1212.3733408123953,202 C 1327.57,221.6 1454.85,511.2 1500.3733408123953,488 C 1545.9,464.8 1452.07,166.4 1440,86",
  "M -15.013501028379892,423 C 42.59,360.8 157.79,113.8 272.9864989716201,112 C 388.19,110.2 445.79,403 560.9864989716201,414 C 676.19,425 733.79,153.2 848.9864989716201,167 C 964.19,180.8 1021.79,512.4 1136.9864989716202,483 C 1252.19,453.6 1309.79,36.4 1424.9864989716202,20 C 1540.19,3.6 1709.98,387 1712.9864989716202,401 C 1715.99,415 1494.6,152.2 1440,90",
  "M -280.0095294005676,440 C -222.41,386.2 -107.21,177.4 7.990470599432385,171 C 123.19,164.6 180.79,435.2 295.9904705994324,408 C 411.19,380.8 468.79,17.6 583.9904705994323,35 C 699.19,52.4 756.79,477.6 871.9904705994323,495 C 987.19,512.4 1044.79,114.2 1159.9904705994325,122 C 1275.19,129.8 1391.99,510 1447.9904705994325,534 C 1503.99,558 1441.6,300.4 1440,242",
  "M -353.59451081308805,284 C -295.99,235.8 -180.79,3.4 -65.59451081308804,43 C 49.61,82.6 107.21,475.2 222.40548918691195,482 C 337.61,488.8 395.21,77 510.40548918691195,77 C 625.61,77 683.21,445.6 798.405489186912,482 C 913.61,518.4 971.21,295 1086.405489186912,259 C 1201.61,223 1303.69,299.4 1374.405489186912,302 C 1445.12,304.6 1426.88,278 1440,272",
  "M -35.41021926322486,100 C 22.19,161.8 137.39,398 252.58978073677514,409 C 367.79,420 425.39,145 540.5897807367751,155 C 655.79,165 713.39,472.2 828.5897807367751,459 C 943.79,445.8 1001.39,117.6 1116.589780736775,89 C 1231.79,60.4 1289.39,290.2 1404.589780736775,316 C 1519.79,341.8 1685.51,206.2 1692.589780736775,218 C 1699.67,229.8 1490.52,343.6 1440,375",
  "M -460.03967014772616,150 C -402.44,215.8 -287.24,466.4 -172.03967014772616,479 C -56.84,491.6 0.76,248.2 115.96032985227384,213 C 231.16,177.8 288.76,292.8 403.96032985227384,303 C 519.16,313.2 576.76,264 691.9603298522738,264 C 807.16,264 864.76,338.6 979.9603298522738,303 C 1095.16,267.4 1175.95,48.6 1267.9603298522738,86 C 1359.97,123.4 1405.59,409.2 1440,490",
  "M -224.2176529362812,253 C -166.62,309 -51.42,538.2 63.782347063718795,533 C 178.98,527.8 236.58,251 351.7823470637188,227 C 466.98,203 524.58,416.4 639.7823470637188,413 C 754.98,409.6 812.58,194.6 927.7823470637188,210 C 1042.98,225.4 1100.58,479.4 1215.7823470637188,490 C 1330.98,500.6 1458.94,278.6 1503.7823470637188,263 C 1548.63,247.4 1452.76,382.2 1440,412",
  "M -53.93207174960472,114 C 3.67,159.6 118.87,349.6 234.06792825039528,342 C 349.27,334.4 406.87,68.2 522.0679282503953,76 C 637.27,83.8 694.87,367.4 810.0679282503953,381 C 925.27,394.6 982.87,139.4 1098.0679282503952,144 C 1213.27,148.6 1270.87,424.4 1386.0679282503952,404 C 1501.27,383.6 1663.28,65.6 1674.0679282503952,42 C 1684.85,18.4 1486.81,237.2 1440,286",
  "M -333.7449344359892,319 C -276.14,259 -160.94,6.8 -45.74493443598921,19 C 69.46,31.2 127.06,363.8 242.2550655640108,380 C 357.46,396.2 415.06,91.2 530.2550655640108,100 C 645.46,108.8 703.06,413.2 818.2550655640108,424 C 933.46,434.8 991.06,161.8 1106.2550655640107,154 C 1221.46,146.2 1327.51,407.8 1394.2550655640107,385 C 1461,362.2 1430.85,109 1440,40",
  "M -265.74226733584584,398 C -208.14,350.8 -92.94,172.4 22.25773266415417,162 C 137.46,151.6 195.06,356.4 310.25773266415416,346 C 425.46,335.6 483.06,87 598.2577326641542,110 C 713.46,133 771.06,458.2 886.2577326641542,461 C 1001.46,463.8 1059.06,144.6 1174.2577326641542,124 C 1289.46,103.4 1409.11,367.2 1462.2577326641542,358 C 1515.41,348.8 1444.45,134 1440,78",
  "M -119.16336671584514,202 C -83.16,219 -11.16,284.8 60.836633284154864,287 C 132.84,289.2 168.84,181 240.83663328415486,213 C 312.84,245 348.84,456.8 420.83663328415486,447 C 492.84,437.2 528.84,174.8 600.8366332841549,164 C 672.84,153.2 708.84,384 780.8366332841549,393 C 852.84,402 888.84,219.8 960.8366332841549,209 C 1032.84,198.2 1068.84,353 1140.836633284155,339 C 1212.84,325 1248.84,120.6 1320.836633284155,139 C 1392.84,157.4 1477,421.6 1500.836633284155,431 C 1524.67,440.4 1452.17,235 1440,186",
  "M -293.64827985917026,305 C -257.65,277.2 -185.65,161.2 -113.64827985917024,166 C -41.65,170.8 -5.65,307.2 66.35172014082976,329 C 138.35,350.8 174.35,255.2 246.35172014082974,275 C 318.35,294.8 354.35,428.8 426.35172014082974,428 C 498.35,427.2 534.35,275.2 606.3517201408298,271 C 678.35,266.8 714.35,434.2 786.3517201408298,407 C 858.35,379.8 894.35,144.8 966.3517201408298,135 C 1038.35,125.2 1074.35,343.4 1146.3517201408297,358 C 1218.35,372.6 1267.62,212.2 1326.3517201408297,208 C 1385.08,203.8 1417.27,311.2 1440,337",
  "M -179.76570899951165,242 C -143.77,251.2 -71.77,282.6 0.23429100048836027,288 C 72.23,293.4 108.23,248.4 180.23429100048835,269 C 252.23,289.6 288.23,407.6 360.2342910004884,391 C 432.23,374.4 468.23,186.2 540.2342910004884,186 C 612.23,185.8 648.23,393.4 720.2342910004884,390 C 792.23,386.6 828.23,176.8 900.2342910004884,169 C 972.23,161.2 1008.23,338 1080.2342910004884,351 C 1152.23,364 1188.23,221.2 1260.2342910004884,234 C 1332.23,246.8 1404.28,420.6 1440.2342910004884,415 C 1476.19,409.4 1440.05,247.8 1440,206",
  "M -253.10474932602165,291 C -217.1,287 -145.1,251.4 -73.10474932602165,271 C -1.1,290.6 34.9,416 106.89525067397835,389 C 178.9,362 214.9,137.4 286.89525067397835,136 C 358.9,134.6 394.9,375.6 466.89525067397835,382 C 538.9,388.4 574.9,185.2 646.8952506739784,168 C 718.9,150.8 754.9,304 826.8952506739784,296 C 898.9,288 934.9,101 1006.8952506739784,128 C 1078.9,155 1114.9,429 1186.8952506739784,431 C 1258.9,433 1316.27,167.4 1366.8952506739784,138 C 1417.52,108.6 1425.38,254.8 1440,284",
  "M -79.8933221658016,389 C -43.89,362.2 28.11,261.6 100.1066778341984,255 C 172.11,248.4 208.11,354.8 280.1066778341984,356 C 352.11,357.2 388.11,254.6 460.1066778341984,261 C 532.11,267.4 568.11,404 640.1066778341984,388 C 712.11,372 748.11,179.6 820.1066778341984,181 C 892.11,182.4 928.11,397.4 1000.1066778341984,395 C 1072.11,392.6 1108.11,162 1180.1066778341983,169 C 1252.11,176 1288.11,421.8 1360.1066778341983,430 C 1432.11,438.2 1524.13,209.4 1540.1066778341983,210 C 1556.09,210.6 1460.02,388.4 1440,433",
  "M -275.77137211639183,395 C -239.77,359.2 -167.77,213.2 -95.77137211639182,216 C -23.77,218.8 12.23,398.2 84.22862788360818,409 C 156.23,419.8 192.23,266.4 264.22862788360817,270 C 336.23,273.6 372.23,440.4 444.22862788360817,427 C 516.23,413.6 552.23,215.2 624.2286278836082,203 C 696.23,190.8 732.23,362.8 804.2286278836082,366 C 876.23,369.2 912.23,217.4 984.2286278836082,219 C 1056.23,220.6 1092.23,380.8 1164.2286278836082,374 C 1236.23,367.2 1289.07,198 1344.2286278836082,185 C 1399.38,172 1420.85,284.2 1440,309",
  "M -343.0539272099538,303 C -307.05,291.8 -235.05,238.2 -163.05392720995386,247 C -91.05,255.8 -55.05,359 16.946072790046145,347 C 88.95,335 124.95,175.6 196.94607279004614,187 C 268.95,198.4 304.95,405.4 376.9460727900462,404 C 448.95,402.6 484.95,170.6 556.9460727900462,180 C 628.95,189.4 664.95,461.6 736.9460727900462,451 C 808.95,440.4 844.95,126.6 916.9460727900462,127 C 988.95,127.4 1024.95,449.2 1096.9460727900462,453 C 1168.95,456.8 1208.34,145.8 1276.9460727900462,146 C 1345.56,146.2 1407.39,392.4 1440,454",
  "M -46.27926552094715,191 C -10.28,240 61.72,428.8 133.72073447905285,436 C 205.72,443.2 241.72,229.2 313.72073447905285,227 C 385.72,224.8 421.72,419 493.72073447905285,425 C 565.72,431 601.72,258.4 673.7207344790529,257 C 745.72,255.6 781.72,437.4 853.7207344790529,418 C 925.72,398.6 961.72,171.2 1033.720734479053,160 C 1105.72,148.8 1141.72,345.2 1213.720734479053,362 C 1285.72,378.8 1321.72,244.4 1393.720734479053,244 C 1465.72,243.6 1564.46,353.4 1573.720734479053,360 C 1582.98,366.6 1466.74,293.6 1440,277",
  "M -217.6991167967607,313 C -181.7,289.8 -109.7,173 -37.69911679676071,197 C 34.3,221 70.3,445 142.3008832032393,433 C 214.3,421 250.3,166.8 322.30088320323927,137 C 394.3,107.2 430.3,286.8 502.30088320323927,284 C 574.3,281.2 610.3,90.4 682.3008832032393,123 C 754.3,155.6 790.3,430.2 862.3008832032393,447 C 934.3,463.8 970.3,220.6 1042.3008832032392,207 C 1114.3,193.4 1150.3,367.2 1222.3008832032392,379 C 1294.3,390.8 1358.76,285.6 1402.3008832032392,266 C 1445.84,246.4 1432.46,278 1440,281",
  "M -350.47124813468747,347 C -314.47,319 -242.47,205.4 -170.47124813468747,207 C -98.47,208.6 -62.47,350 9.528751865312529,355 C 81.53,360 117.53,225.8 189.52875186531253,232 C 261.53,238.2 297.53,390 369.52875186531253,386 C 441.53,382 477.53,214.4 549.5287518653125,212 C 621.53,209.6 657.53,394.6 729.5287518653125,374 C 801.53,353.4 837.53,91.8 909.5287518653125,109 C 981.53,126.2 1017.53,454.2 1089.5287518653126,460 C 1161.53,465.8 1199.43,171.6 1269.5287518653126,138 C 1339.62,104.4 1405.91,261.2 1440,292",
]

const customizeSVG = (mode) => {
  
  return ReactDOMServer.renderToString(
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.1" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      // xmlnsSvgjs="http://svgjs.com/svgjs" 
      width="1440" 
      height="560" 
      preserveAspectRatio="none" 
      viewBox="0 0 1440 560">
        <g mask="url(&quot;#SvgjsMask1026&quot;)" fill="none">
          <rect width="1440" height="560" x="0" y="0" fill={palette[mode === 'dark' ? 'primary' : 'secondary'].main}></rect>
          <path key="00" stroke={palette[mode === 'dark' ? 'primary' : 'secondary'].light} strokeWidth="2" d={PATHS[getRandomArbitrary(0, PATHS.length - 1)]}></path>,
          <path key="01" stroke={palette[mode === 'dark' ? 'primary' : 'secondary'].light} strokeWidth="2" d={PATHS[getRandomArbitrary(0, PATHS.length - 1)]}></path>,
          <path key="02" stroke={palette[mode === 'dark' ? 'primary' : 'secondary'].light} strokeWidth="2" d={PATHS[getRandomArbitrary(0, PATHS.length - 1)]}></path>,
          <path key="03" stroke={palette[mode === 'dark' ? 'secondary' : 'primary'].main } strokeWidth="2" d={PATHS[getRandomArbitrary(0, PATHS.length - 1)]}></path>,
        </g>
        <defs>
          <mask id="SvgjsMask1026">
            <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
        </defs>
      </svg>
  )
}

export default function emptyState() {
  const mode = Math.random() < 0.5 ? 'dark': 'light';
  const image = customizeSVG(mode)
  const buff = new Buffer(image);
  const base64data = buff.toString('base64');
  
  return `data:image/svg+xml;base64,${base64data }`
} 