
import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Logo } from 'images/Logo Primary - no background.svg'
import { ACCEPT_INVITE, PROFILE } from 'apollo/queries';
import getRoute, { root, login } from 'routes/nav';
import { decryptParams } from 'utils/urls';
import './Invite.css';

const classes = {
  layout_o: "w-auto mw-s center-ns bg-white  ph7 pb6 pt8",
  layout_i: "flex flex-column items-center",
  form: "w-100",
  submit: "w-100 mt6",
  link: "mb3 no-underline primary hover-secondary link nowrap",
  bold: "f4 fw5 mv5 tc",
  hr: "w-100 mv0 black-20 ba bb-0",
  or_wrapper: "relative mv6",
  or_text: "absolute absolute--center bg-white pa2",
};

const Invite = ({ route, dispatchSession }) => {

  const { enqueueSnackbar } = useSnackbar();

  const [getProfile] = useLazyQuery(PROFILE, {
    onCompleted: (res) => {
      return route.history.replace(getRoute(root));
    },
    onError: (err) => console.error("Error creating a post", err),
  })

  const [acceptInvite, { loading }] = useMutation(ACCEPT_INVITE, {
    onCompleted: ({ acceptInvite }) => {
      dispatchSession({ session: acceptInvite });
      getProfile();
      enqueueSnackbar(t('Invite succesfully accepted!'), { variant: 'success' })
    },
    onError: (err) => {
      switch(err.message) {
        case 'not_found':
          enqueueSnackbar(t('Invite was not found or already accepted'), { variant: 'error' })
          break;
        default:
          enqueueSnackbar(t('Error accepting the invite'), { variant: 'error' })
      }
    },
  })

  const [values, setValues] = React.useState({
    acceptsTerms: false,
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (loading || !route?.match) {
    return (
      <Box className="flex flex-grow-1 justify-evenly items-center h-100">
        <CircularProgress />
      </Box>
    )
  }

  // if (error) { // need the status here
  //   // return <ErrorPage {...error}/>
  //   return <div>error :)</div>
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password, acceptTerms } = e.target.elements;
    acceptInvite({ variables: {
      teamId: inviteData.teamId,
      inviteHash: inviteData.inviteHash,
      data: {
        username: username?.value,
        password: password?.value,
        termsAccepted: acceptTerms?.checked
      }
    }})
  }

  const { inviteHash } = route.match?.params;

  if (!inviteHash) {
    setTimeout(() => {
      route.history.push(getRoute(login))
    })
    return null;
  }

  const inviteData = decryptParams(inviteHash);

  return (
    <Box className="invite pa8 flex flex-column items-center justify-center">
      <Avatar sx={{ m: 1, height: 128, width: 128, background: 'none' }}>
        <Logo />
      </Avatar>
      <Box className="flex flex-column items-center justify-center">
        <h3 className="mb4 tc">{`${inviteData.ownerName} has invited you to join team ${inviteData.teamName}`}</h3>
        <form onSubmit={handleSubmit} className={classes.form}>
          {JSON.parse(inviteData.newUser) && <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("Your @username")}
              name="username"
              type="text"
              autoFocus
            />}
          { JSON.parse(inviteData.newUser) && <TextField
              id="password"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Create a password")}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              autoComplete="current-password"
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
          {JSON.parse(inviteData.newUser) && <FormControlLabel
            id="acceptsPrivacy"
            className="pb4"
            control={
              <Checkbox required onChange={handleChange('acceptsTerms')} />
            }
            label={
              <Typography>
                {t('I Accept the')}{' '}
                <Link target="_blank" href={getRoute(root)}>{t('privacy policy and terms of service')}</Link>
              </Typography>
            }
          />}
          <LoadingButton
            type="submit"
            variant='contained'
            className={classes.submit}
          >
            {t('Accept Invitation')}
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}

export default Invite;