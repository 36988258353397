import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import { UPDATE_PASSWORD } from 'apollo/queries';
import InputPassword from 'common/components/Form/Input/Password/InputPassword';
import { snackbarSuccess, snackbarError } from 'dispatcher/snackbar';


const MIN_PASSWORD_LENGTH = 6;

export default function EditPassword({ close }) {

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (e) => { snackbarSuccess(t('Constraseña actualizada')) },
    onError: (e) => { snackbarError(t('Error actualizando la constraseña')) }
  })

  const [password, setPassword] = useState({
    oldPassword: null,
    newPassword: null,
    repeatNewPassword: null,
  })

  const handleSave = async (e) => {
    e.preventDefault();
    const { elements } = e.target;

    await updatePassword({
      variables: {
        data: {
          newPassword: elements.newPassword.value,
          oldPassword: elements.oldPassword.value
        },
      }
    })
    close()
  }
  
  const newPasswordIsValid = !!password.newPassword && password.newPassword.length >= MIN_PASSWORD_LENGTH;
  const passwordMismatch = !!password.repeatNewPassword && password.newPassword !== password.repeatNewPassword;
  const canSubmit = !passwordMismatch && !!password.oldPassword && !!password.newPassword && password.newPassword.length >= MIN_PASSWORD_LENGTH;

  return (
    <Container className="EditPassword">
      <form onSubmit={handleSave} autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <h5>{t('Actualiza tu contraseña (mínimo 6 caracteres)')}</h5>
          </Grid>
          <Grid item xs={12} className="pb6">
            <FormControl variant="standard" className="w-100">
              <InputPassword
                required
                autoFocus
                id="oldPassword"
                label={t('Tu contraseña actual')}
                defaultValue=""
                onBlur={(e) => { setPassword({...password, oldPassword: e.target.value }) }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="pb6">
            <FormControl variant="standard" className="w-100">
              <InputPassword
                required
                id="newPassword"
                label={t('Tu nueva contraseña')}
                defaultValue=""
                onBlur={(e) => { setPassword({...password, newPassword: e.target.value }) }}
                error={ (password.newPassword !== null && !newPasswordIsValid) || undefined }
                helperText={ password.newPassword !== null && !newPasswordIsValid ? t('La nueva contraseña no es válida') : undefined }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="pb6">
            <FormControl variant="standard" className="w-100">
              <InputPassword
                required
                id="repeatNewPassword"
                label={t('Repite tu nueva contraseña')}
                defaultValue=""
                onBlur={(e) => { setPassword({...password, repeatNewPassword: e.target.value }) }}
                error={ passwordMismatch || undefined }
                helperText={ passwordMismatch ? t('La nueva contraseña no coincide') : undefined }
              />
            </FormControl>
          </Grid>
          <Grid container className="pb4">
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <LoadingButton 
                color="error"
                onClick={() => close()}
              >
                {t('CANCELAR')}
              </LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <LoadingButton
                type="submit"
                loading={loading}
                disabled={!canSubmit}
              >
                {t('GUARDAR')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}