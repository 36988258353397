import { ApolloLink } from '@apollo/client';
import ProjectLinks from 'apollo/links/_index';
import authFlowLink from './authFlow';
import errorLink from './errorLink';
import userLink  from './userLink';

const defaultLinks = ApolloLink.from([
  authFlowLink,
  errorLink,
  userLink,
])

const combinedLinks = [
  defaultLinks,
]

if (ProjectLinks.length) {
  combinedLinks.push(ApolloLink.from(ProjectLinks));
}

export default ApolloLink.from(combinedLinks);