import { ApolloLink } from '@apollo/client';
import { fromPromise } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import store from 'redux/store';
import { session as setSession, logout } from 'redux/actions';
import { client as apolloClient } from 'apollo';
import { LOGIN_REFRESH } from 'apollo/queries';

const getNewSession = async (hash) => {
  return apolloClient.query({
    query: LOGIN_REFRESH,
    variables: {
      hash,
    },
  }).then((response) => {
    return response.data.loginRefresh;
  });
};

const setAuthorizationHeaders = (headers, session) => {
  let { accessToken, type } = session;
  return {
    headers: {
      ...headers,
      ...(accessToken && { authorization: `${type} ${accessToken}` }),
    }
  }
}

const withToken = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  let { session } = store.getState().user;
  if (!session) return;

  return setAuthorizationHeaders(headers, session);
});


const validToken = new ApolloLink((operation, forward) => {
  
  let { session } = store.getState().user;
  const now = new Date().getTime();
  const refreshOperation = LOGIN_REFRESH.definitions[0].name.value;
  const isLoginRefresh =  operation.operationName === refreshOperation;

  if(isLoginRefresh) return forward(operation);

  if (!session || (!!session && session.expiration > now)) {
    return forward(operation);
  }

  const { hash } = session.refreshToken;
  return fromPromise(
    getNewSession(hash)
  )
  .filter((value) => Boolean(value))
  .flatMap((newSession) => {
    const oldHeaders = JSON.stringify(operation.getContext().headers);
    operation.setContext(setAuthorizationHeaders(oldHeaders, newSession));
    store.dispatch(setSession({ session: newSession }))
    return forward(operation);
  });
})

const resetSession = onError(({ networkError, graphQLErrors }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 401
  ) {
    // remove cached token on 401 from the server
    store.dispatch(logout());
  }
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      const { code } = error.extensions;
      switch (parseInt(code)) {
        case 401:
          store.dispatch(logout());
          break;
        default:
          break;
      }
      return null;
    })
  }
});

export default ApolloLink.from([withToken, validToken, resetSession]);