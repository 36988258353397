import securedWithProfile from 'hocs/securedWithProfile';
import Recipes from 'containers/Recipes';
import Recipe from 'containers/Recipe';

export const recipes = {
  title: "Recipes",
  component: securedWithProfile(Recipes),
  paths: {
    en: '/recipes',
    es: '/recipes',
    pt: '/recipes',
  },
  parentRoute: null,
  hideSidebar: true,
};

export const recipe = {
  title: "Recipe",
  component: securedWithProfile(Recipe),
  paths: {
    en: '/recipes/:id',
    es: '/recipes/:id',
    pt: '/recipes/:id',
  },
  parentRoute: recipes,
  hideSidebar: true,
};