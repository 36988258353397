import React from 'react';
import { useQuery } from '@apollo/client';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { GET_TECHNIQUES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';

export default function RecipeTechniqueSelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_TECHNIQUES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  
  const { getTechniques: techniques } = data;
  return (
    <FormControl sx={{ my: 1, width: '100%' }} required={required}>
      <InputLabel id="glass-type-selector-label">{t('Technique')}</InputLabel>
      <NativeSelect
          id="techinque-selector"
          label="Categoria de la receta"
          defaultValue={defaultValue}
          onChange={onChange}
          input={<OutlinedInput type="select" required={required} />}
         >
          <option hidden value=""></option>
          {techniques && techniques.map((technique, index) =>
            <option key={index} value={technique.id}>{technique.name}</option>
          )}
      </NativeSelect>
    </FormControl>
  )
}