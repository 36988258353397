import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useLongPress } from 'use-long-press';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import getRoute, { fillInTest, fillInTestScore } from 'routes/nav';
import { dateToLocaleString, dateToTimeString } from 'utils/date';
import { GET_MY_TESTS } from 'apollo/queries';
import DeleteDialog from './dialogs/delete';
import { ListItemButton } from '@mui/material';

export default function MyResults({ route }) {

  const { loading, error, data } = useQuery(GET_MY_TESTS);

  const [deleteDialog, toggleDeleteDialog] = useState({
    opened: false,
    test: null,
  });

  const callback = useCallback(event => {
    toggleDeleteDialog({
      ...deleteDialog,
      opened: true,
    })
  }, [deleteDialog]);

  const bind = useLongPress(callback, {
    onStart: (event, { context }) => toggleDeleteDialog({
      ...deleteDialog,
      test: context,
    }),
    cancelOnMovement: true,
    captureEvent: true,
  });

  if (loading || !data || !route) {
    return (
      <Box className="flex flex-grow-1 justify-evenly items-center h-100">
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>Error {error}</div>
  }

  const { getMyTests: tests } = data;

  if(tests.length <= 0) {
    return (
      <Box className="flex flex-grow-1 justify-evenly items-center h-100 pa8">
        <Paper elevation={0} className="pa4">
          <Typography sx={{ fontSize: '3rem', paddingBottom: '2rem' }} align="center">
            <span role="img" aria-label="emoji">🍸</span>
          </Typography>
          <Typography sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
            {t('You didn\'t start any tests yet!')}
          </Typography>
        </Paper>
      </Box>
    )
  }
  
  return (
    <List className="MyResults">
      { 
        tests.length > 0 && tests.map((test, i) => {
          
          const { id, createdAt, endedAt } = test;
          const isEnded = !!endedAt;

          return (
            <React.Fragment key={`${id}_${i}`}>
              <ListItem>
                <ListItemButton
                  {...bind(test)}
                  onClick={(e) => {
                    if (isEnded) {
                      return route.history.push(getRoute(fillInTestScore, { params: { id } }))
                    }
                    return route.history.push(getRoute(fillInTest, { params: { id } }))
                  }}
                >
                  <Grid container className="Test items-center pt2 pb2">
                    <Grid item xs={2}>
                      <DescriptionIcon />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="caption" fontWeight={'bold'} fontSize={14} style={{ margin: 0 }}>{t('Type')} {test.type}</Typography> 
                      { isEnded ? 
                        <Typography className="f4">{`${t('Score')} ${parseFloat(test.score)}%`}</Typography> 
                        : 
                        <Typography className="f4">{t('Not Finished')}</Typography> 
                      }
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" className="ma0">{dateToLocaleString(createdAt, 'short')}</Typography>
                      <Typography variant="body2" className="ma0">{dateToTimeString(createdAt)}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="edit">
                        <ChevronRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          )
        })
      }
      <DeleteDialog opened={deleteDialog.opened} toggle={toggleDeleteDialog} test={deleteDialog.test}/>
    </List>
  )
}