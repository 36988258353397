import { gql } from '@apollo/client';

export const GET_RECIPE_TYPES = gql`
  query {
    getRecipeTypes {
      id,
      name,
    }
  }
`;

export const GET_GLASS_TYPES = gql`
  query {
    getGlassTypes {
      id,
      name,
    }
  }
`;

export const GET_RECIPE_CATEGORIES = gql`
  query {
    getRecipeCategories {
      id,
      name,
    }
  }
`;

export const GET_RECIPES = gql`
  query GET_RECIPES ($page: Int, $size: Int, $sort: InputSort, $search: String, $filters: InputFilter) {
    getRecipes (page: $page, size: $size, sort: $sort, search: $search, filters: $filters) {
      pagination {
        totalItems,
        size
        page,
      },
      items {
        id,
        type {
          id,
          name
        },
        parent {
          id,
          title
        },
        title,
        category {
          id,
          name
        },
        description,
        components {
          ingredient {
            id,
            name
          },      
          amount,
          measurement {
            name,
          }
        },
        techniques {
          id,
          name
        },
        decoration {
          ingredients {
            ingredient {
              id
              name
            }
          },
          description,
        },
        elaboration {
          description
        },
        team {
          id
          name
        }
      }
    }
  }
`;

export const GET_RECIPE = gql`
  query getRecipe($id: ID!){
    getRecipe(id: $id) {
      id,
      owner {
        id,
        username
      },
      team {
        id,
        name
      },
      type {
        id,
        name
      },
      title,
      category {
        id,
        name
      },
      description,
      tastingNotes,
      history,
      parent {
        id,
        title
      },
      elaboration {
        description
      },
      components {
        ingredient {
          id,
          name
        },      
        amount,
        measurement {
          name,
        }
      },
      techniques {
        id,
        name
      },
      decoration {
        ingredients {
          ingredient {
            id
            name
          }
        },
        description,
      },
      glassType {
        id,
        name
      },
      iceType,
    }
  }
`;


export const GET_RECIPES_FILTERED = gql`
  query GET_RECIPES ($page: Int, $size: Int, $sort: InputSort, $search: String, $filters: InputFilter) {
    getRecipes (page: $page, size: $size, sort: $sort, search: $search, filters: $filters) {
      pagination {
        totalItems,
        size
      }
      items {
        id
      }
    }
  }
`;