import securedWithProfile from 'hocs/securedWithProfile';
import Tests from 'containers/Tests';
import FillInTest from 'containers/FillInTest';
import FillInTestScore from 'containers/FillInTest/Score';
import FillInTestFilters from 'containers/FillInTest/Filters';


export const tests = {
  title: "Tests",
  component: securedWithProfile(Tests),
  paths: {
    en: '/tests',
    es: '/tests',
    pt: '/tests',
  },
  parentRoute: null,
  hideSidebar: true,
};

export const fillInTest = {
  title: "Fill in Recipe",
  component: securedWithProfile(FillInTest),
  paths: {
    en: '/tests/fill-in/:id?',
    es: '/tests/fill-in/:id?',
    pt: '/tests/fill-in/:id?',
  },
  parentRoute: tests,
  hideSidebar: true,
};

export const fillInTestScore = {
  title: "Fill in Test Score",
  component: securedWithProfile(FillInTestScore),
  paths: {
    en: '/tests/fill-in/:id/score',
    es: '/tests/fill-in/:id/score',
    pt: '/tests/fill-in/:id/score',
  },
  parentRoute: tests,
  hideSidebar: true,
};

export const fillInTestFilters = {
  title: "Fill in Recipe: Filter",
  component: securedWithProfile(FillInTestFilters),
  paths: {
    en: '/tests/fill-in-filter',
    es: '/tests/fill-in-filter',
    pt: '/tests/fill-in-filter',
  },
  parentRoute: fillInTest,
  hideSidebar: true,
};