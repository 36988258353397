import securedWithProfile from 'hocs/securedWithProfile';
import Homepage from 'containers/Homepage';
import Login from 'containers/Login';
import Profile from 'containers/Profile';
import Invite from 'containers/Invite';
export * from './tests';
export * from './recipes';

export const root = {
  title: "Home",
  component: Homepage,
  paths: {
    en: '/',
    es: '/',
    pt: '/',
  },
  parentRoute: null,
  hideSidebar: true,
  hideFooter: true,
  hideHeader: true,
};

export const login = {
  title: "Login",
  component: Login,
  paths: {
    en: '/login',
    es: '/login',
    pt: '/login',
  },
  parentRoute: null,
  hideSidebar: true,
  hideHeader: true,
  hideFooter: true,
};

export const profile = {
  title: "Profile",
  component: securedWithProfile(Profile),
  paths: {
    en: '/profile',
    es: '/profile',
    pt: '/profile',
  },
  hideSidebar: true,
  hideHeader: false,
  hideFooter: false,
};

export const invite = {
  title: "Invite",
  component: Invite,
  paths: {
    en: '/invite/:inviteHash',
    es: '/invite/:inviteHash',
    pt: '/invite/:inviteHash',
  },
  hideSidebar: true,
  hideHeader: true,
  hideFooter: true,
};