import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LOGIN } from 'apollo/queries';
import { LocalStorage } from 'utils';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Logo } from 'images/Logo Primary - no background.svg'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function Copyright(props) {
  return (
    <Typography variant="body2" color="primary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mixole.com/">
        mixole.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {

  const { enqueueSnackbar } = useSnackbar();

  const [login, { called, data, loading, error }] = useLazyQuery(LOGIN, {
    onCompleted: (res) => console.log("Data from query", res, called, data, error),
    onError: (err) => enqueueSnackbar('Incorrect email or password', { variant: 'error' }),
  });

  const [values, setValues] = React.useState({
    rememberMe: false,
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    LocalStorage.set('rememberSession', values.rememberMe);
    login({ variables: {
      email: data.get('email'),
      password: data.get('password'),
    }})
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, height: 128, width: 128, background: 'none' }}>
            <Logo />
          </Avatar>
          <form component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email Address")}
              // InputLabelProps={{
              //   sx: { color: 'secondary.main' },
              // }}
              name="email"
              type="email"
              autoComplete="email"
              // color="secondary"
              // sx={{ borderColor: 'secondary.main' }}
              autoFocus
            />
            <TextField
              id="password"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              // InputLabelProps={{
              //   sx: { color: 'secondary.main' },
              // }}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              autoComplete="current-password"
              // color="secondary"
              onChange={handleChange('password')}
              InputProps={{
                // color: "secondary",
                // style: { border: 'secondary', color: 'secondary' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      // color="secondary"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              id="remember"
              control={
                <Checkbox checked={values.rememberMe} onChange={handleChange('rememberMe')} 
                  // color="secondary" sx={{ color: 'secondary.main' }} 
                />
              }
              label={
                <Typography color="primary">
                  {t("Remember me")}
                </Typography>
              }
            />
            <LoadingButton
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              // color='secondary'
              sx={{ mt: 3, mb: 2 }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('Sign In')}
              </Typography>
            </LoadingButton>
            {/* <Grid container>
            loading
              <Grid item xs>
                <Link href="#" variant="body2">
                  {t('Forgot password?')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {t("Don't have an account? Sign Up")}
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}