import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import getRoute, { tests } from 'routes/nav';
import { ReactComponent as Logo } from 'images/Logo Secondary - no background.svg';

export default function Homepage({ route }) {

  const [value, setValue] = useState(0);

  let theme = useTheme()
  theme = createTheme(theme, {
    components: {
      MuiMobileStepper: {
        styleOverrides: {
          dotActive: {
            backgroundColor: '#CBB26B',
          },
          dots: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }
        },
      },
    },
  });

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <Box className="w-100 h-100" sx={{ bgcolor: 'primary.main' }}>
      <header className="flex flex-column items-center w-100 pa8">
        <Avatar sx={{ m: 1, height: 128, width: 128, background: 'none' }}>
          <Logo />
        </Avatar>
      </header>
      <Box className="w-100 h-100" sx={{ paddingTop: '14rem', paddingBottom: '6rem' }}>
      <SwipeableViews
          style={{flex: 1, height: '100%' }}
          containerStyle={{ height: '100%', paddingBottom: '3.5rem' }}
          index={value}
          onChangeIndex={handleChangeIndex}
      >
        <Box className="flex flex-column items-center justify-evenly pa6">
          <Typography variant="h4" mb={4} color="secondary">
            {t('Welcome to Mixole')}
          </Typography>
          <Typography variant="body1" mb={2} color="secondary">
            {t('Mixole is not just another recipe app that you eventually stop using...')}
          </Typography>
          <Typography variant="body1" color="secondary">
            <b>{t('Mixole is much more than that!')}</b> <br/>{t('It\'s a tool on which you can change the way you learn new cocktails and don\'t forget those you already know but don\'t make very often!')}
          </Typography>
        </Box>
        <Box className="flex flex-column items-center justify-evenly pa6">
          <Typography variant="h4" mb={4} color="secondary">
            {t('Mastering Mixology')}
          </Typography>
          <Typography variant="body1" mb={2} color="secondary">
            {t('When we learn new cocktails, we already know that the most important thing is to be behind the bar and repeat them over and over again until we have them memorized.')}
          </Typography>
          <Typography variant="body1" mb={2} color="secondary">
            {t('But there is also a theory side of it, where with pen and paper we have to memorize lists of new cocktails.')}
          </Typography>
        </Box>
        <Box className="flex flex-column items-center justify-evenly pa6">
          <Typography variant="h4" mb={4} color="secondary">
            {t('Right on your hand')}
          </Typography>
          <Typography variant="body1" mb={4} color="secondary">
            {t('At Mixole we are passionate about bartending and our goal is to help anyone who wants to learn and share this passion.')}
          </Typography>
          <Typography variant="body1" color="secondary">
            {t('Now you can have at your disposal a tool with which you can leave aside the pen and paper to have everything you need in your smartphone')}
          </Typography>
        </Box>
      </SwipeableViews>
      </Box>
      <Box className="Footer fixed flex flex-column pa8 w-100 bottom-0">
        <Box className="pb6">
          <ThemeProvider theme={theme}>
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={value}
              className="flex justify-around w-100"
              sx={{ width: '100%', flexGrow: 1, backgroundColor: 'primary.main' }}
            />
          </ThemeProvider>
        </Box>
        <Button 
          variant="contained" 
          color="secondary"
          onClick={() => {
            value === 2 ? route.history.push(getRoute(tests)) : setValue(value + 1);
          }}
        >
          { value === 2 ? t('Get Started!') : t('Next') }
        </Button>
      </Box>
    </Box>
  )
}
