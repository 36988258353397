import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { deepClone } from 'utils';
import getRoute, { fillInTestScore } from 'routes/nav';
import { GET_TEST, UPDATE_TEST, END_TEST } from 'apollo/queries';
import RecipeTechniqueSelector from 'components/RecipeTechniqueSelector';
import RecipeIceTypeSelector from 'components/RecipeIceTypeSelector';
import RecipeGlassTypeSelector from 'components/RecipeGlassTypeSelector';
import SelectionScreen from './SelectionScreen';
import AddIngredient from './AddIngredient';
import './FillInTest.css';

const DEFAULT_ANSWER = {
  techniques: [],
  glassType: '',
  iceType: '',
  components: [],
};

export default function FillInTest({ route }) {

  const { loading, error, data } = useQuery(GET_TEST, {
    variables: {
      id: route?.match.params.id,
    },
    skip: !route?.match.params.id
  });

  const [updateTest] = useMutation(UPDATE_TEST, {
    onCompleted: (e) => { console.log('UPDATED', e) },
    onError: (e) => { console.error('Error', e) }
  })

  const [endTest] = useMutation(END_TEST, {
    onCompleted: (e) => { console.log('ENDED', e); goToResults() },
    onError: (e) => { console.error('Error', e) }
  })

  const [currentQuestion, changeQuestion] = useState(0);
  const [answer, updateAnswer] = useState(DEFAULT_ANSWER);
  const [reset, resetForm] = useState('init');
  const [addingIngredient, toggleAddIngredientDialog] = useState(false);

  const goToResults = () => {
    route.history.replace(getRoute(fillInTestScore, { params: { id }}))
  }

  const handleUpdateAnswer = (prop) => (event) => {
    if(prop === 'components') {
      answer.components.push(event)
      console.log('UPDATE_ANSWER_components', prop, event)
      updateAnswer({ ...answer });
    } else if (prop === 'techniques') {
      console.log('UPDATE_ANSWER_techniques', prop, event.target.value)
      updateAnswer({ ...answer, [prop]: [event.target.value] });
    } else {
      console.log('UPDATE_ANSWER_else', prop, event.target.value)
      updateAnswer({ ...answer, [prop]: event.target.value });
    }
  }

  const handleRemoveIngredient = (index) => {
    answer.components.splice(index, 1)
    updateAnswer({ ...answer })
  }

  const handleSubmitAnswer = (e) => {
    e.preventDefault();
    const submitterId = e.nativeEvent.submitter.id;
    console.log('SUBMIT', submitterId, answer)

    answer.components.forEach(component => {
      component.ingredient = component.ingredient.id;
    })

    if (submitterId === 'update') {

      updateTest({
        variables: {
          id: route.match.params.id,
          type: 'fill-in',
          data: answer,
        },
        refetchQueries: [{
          query: GET_TEST,
          variables: {
            id: route.match.params.id,
          }
        }]
      })
      updateAnswer(deepClone(DEFAULT_ANSWER))
      resetForm(new Date().getTime())

    } else if (submitterId === 'finish') {

      endTest({
        variables: {
          id: route.match.params.id,
          type: 'fill-in',
          data: answer,
        }
      })
    }
  }

  useEffect(() => {
    if (data) {
      const current = data.getTest.length ? data.getTest.answers.length : data.getTest.questions.length - 1;
      const question = data.getTest.questions[current];
      updateAnswer({
        ...deepClone(DEFAULT_ANSWER),
        title: question.title,
        category: question.category.id,
        type: question.type.id,
      })
      changeQuestion(current)
    }
  }, [data])

  if (!route) return null;
  if (!route.match.params.id) { return <SelectionScreen route={route} /> }
  if (loading) {
    return (
      <Box className="Loader flex flex-grow-1 justify-evenly items-center">
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>Error {error}</div>
  }

  const { id, answers, questions, length, endedAt } = data.getTest;

  if (endedAt !== null) {
    goToResults();
  }

  return (
    <Box className="Container WithHeader WithFooter FillInTest">
      <AppBar id="fill-in-test-header" className="Header" position="static">
        <Grid container>
          <Grid item xs={6} sx={{ padding: 1.5, mb: 1 }}>
            <Typography variant="caption" fontSize={14}>
              { length === null ? t('Endless Mode'): t('Custom Mode')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: 1.5, mb: 1 }}>
            <Typography variant="caption" fontSize={14}>
              { length === null ? `${currentQuestion + 1} ${t('total')}`: `${currentQuestion + 1} out of ${length}`}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: 1.5 }}>
            <Typography variant="caption" fontSize={14}>
              {t(questions[currentQuestion].title)}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ padding: 1.5, mb: 1 }}>
            <Typography variant="caption" fontSize={14}>
              {t(questions[currentQuestion].category.name)}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
      <Container className="Test">
        <Grid container className="pa4">
          <Grid item xs={12}>
            <form className="w-100" id="testForm" component="form" onSubmit={handleSubmitAnswer} style={{ overflow: 'scroll' }}>
              <Typography variant="h6">{t('Basics')}</Typography>
              <RecipeTechniqueSelector
                required={true}
                key={`${reset}_technique`}
                onChange={handleUpdateAnswer('techniques')}
              />
              <RecipeGlassTypeSelector
                required={true}
                key={`${reset}_glassType`}
                onChange={handleUpdateAnswer('glassType')}
              />
              <RecipeIceTypeSelector
                required={true}
                key={`${reset}_iceType`}
                onChange={handleUpdateAnswer('iceType')}
              />
                { answer.components.length > 0 && <Typography variant="h6" sx={{ paddingTop: '1rem'}}>{t('Components')}</Typography> }
                <List>
                  { answer.components?.map((component, i) => {
                      return (
                        <React.Fragment key={`${component.ingredient.id}_${i}`}>
                          <ListItem>
                            <Grid container className="flex items-center">
                              <Grid item xs={10}>
                                <ListItemText primary={component.ingredient.name} secondary={`${component.amount} ${component.measurement.name}`} />
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton onClick={() => handleRemoveIngredient(i)}>
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      )
                    })
                  }
                </List>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Container id="fill-in-test-footer" className="Footer fixed" style={{ bottom: '3.5rem', background: 'white' }}>
        <Grid item xs={12}>
          <Button
                id="addIngredient"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 1 }}
                onClick={() => toggleAddIngredientDialog(true)}
            >
              {t('Add Ingredient')}
          </Button>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <Button
              id="update"
              type="submit"
              form="testForm"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disabled={length && (answers.length === questions.length -1)}
            >
              {t('NEXT')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <Button
                id="finish"
                type="submit"
                form="testForm"
                fullWidth
                variant="outlined"
                sx={{ mt: 2, mb: 2 }}
            >
              {t('FINISH')}
            </Button>
          </Grid>
        </Grid>
      </Container>
      {console.log('addingIngredient', addingIngredient)}
      <AddIngredient 
        opened={addingIngredient}
        onClose={() => {
          toggleAddIngredientDialog(false)
        }}
        onSave={(component) => {
          toggleAddIngredientDialog(false)
          handleUpdateAnswer('components', null)(component)
        }}
      />
    </Box>
  )
}