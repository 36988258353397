import { ApolloLink } from '@apollo/client';
// import { fromPromise } from '@apollo/client';
// import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import store from 'redux/store';
import { errorPage as setErrorPage } from 'redux/actions';
import { ERROR_CODES } from 'hocs/ErrorPageProvider';

const handleErrors = onError(({ networkError, graphQLErrors, operation, forward, response }) => {

  if (
    networkError
  ) {
    const code = ERROR_CODES.INTERNAL_SERVER_ERROR;
    const error = t('Failed to Connect');
    store.dispatch(setErrorPage({
      code,
      error,
    }));
  }

  if (
    networkError
    && networkError.name === 'ServerError'
    && networkError.statusCode === 500
  ) {
    const code = ERROR_CODES.INTERNAL_SERVER_ERROR;
    store.dispatch(setErrorPage({
      code,
      error: networkError.name,
    }));
  }
  
  if (graphQLErrors && graphQLErrors.length) {
    graphQLErrors.forEach(({ message, extensions }) =>
    console.error(`Error
      Code: ${extensions.code}
      Message: ${message}: ${extensions.errors && extensions.errors[0]?.description} 
    `));
  }

  if (
    graphQLErrors
    && graphQLErrors[0]?.extensions.code === 'INTERNAL_SERVER_ERROR'
  ) {
    const code = ERROR_CODES.INTERNAL_SERVER_ERROR;
    const error = graphQLErrors[0];
    store.dispatch(setErrorPage({
      code,
      error,
    }));
  }
  
});

export default ApolloLink.from([handleErrors]);