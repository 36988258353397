import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { setScroll } from 'redux/actions';
import { GET_RECIPES } from 'apollo/queries';
import getRoute, { recipe as recipeRoute } from 'routes/nav';
import emptyState from 'utils/emptyState';
import Header from './Header';
import './Recipes.css';

const INIT_PAGE = 1;
const PAGE_SIZE = 10;
const EMPTY_STATES = new Array(PAGE_SIZE).fill(null).map(() => emptyState())

export default function Recipes ({ route, store }) {

  const { search, filters } = queryString.parse(window?.location.search);
  const parsedFilters = JSON.parse(decodeURIComponent(filters || '{}'))

  const [recipes, updateList] = useState([])
  const [scrollTop, setScrollTop] = useState(0);

  const { loading, data, fetchMore } = useQuery(GET_RECIPES, {
    variables: {
      filters: {
        ...(parsedFilters.techniques?.length && { techniques: parsedFilters.techniques }),
        ...(parsedFilters.glassType?.length && { glassType: parsedFilters.glassType }),
        ...(parsedFilters.category?.length && { category: parsedFilters.category }),
      },
      ...(search && { search: decodeURIComponent(search) }),
      page: INIT_PAGE,
      size: PAGE_SIZE,
    },
    fetchPolicy: 'cache-first'
  })

  const handleClear = () => {
    const query = queryString.parse(window?.location.search);
    delete query.search;
    route.history.push(`${window.location.pathname}?${queryString.stringify(query)}`);
  }

  const handleSearch = ({ value }) => {
    const query = queryString.parse(window?.location.search);
    query.search = encodeURIComponent(value);
    if (!value) delete query.search
    route.history.push(`${window.location.pathname}?${queryString.stringify(query)}`);
  }

  const handleFilter = (value) => {
    const query = queryString.parse(window?.location.search);
    query.filters = encodeURIComponent(JSON.stringify(value));
    if (!value) delete query.filters
    route.history.push(`${window.location.pathname}?${queryString.stringify(query)}`);
  }

  const getNextPage = (data) => {
    const { page, totalItems } = data.getRecipes.pagination;
    return {
      page: page + 1,
      size: PAGE_SIZE,
      totalItems: totalItems,
    }
  }

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    const previousRecipes = previousResult.getRecipes?.items || data.getRecipes.items;
    const newRecipes = fetchMoreResult.getRecipes.items;
    return { 
      getRecipes: {
        items: [...previousRecipes, ...newRecipes],
        pagination: fetchMoreResult.getRecipes.pagination,
      }
    }
  }

  const handleMorePages = (e) => {
    fetchMore({
      variables : getNextPage(data),
      updateQuery,
    })
  }

  useEffect(() => {
    if (data) {
      updateList(data.getRecipes.items)
    }
  }, [data])

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset;
      const scroll = currentPosition <= 0 ? 0 : currentPosition;
      setScrollTop(scroll);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop])

  useEffect(() => {
    const scrollTop = store.getState().scroll.scrollTop
    if(scrollTop) {
      setTimeout(() => {
        window.scrollTo(0, scrollTop);
      })
    }
  }, [store])


  if (!route) return null
  if (loading || !data) {
    return (
      <Box className="flex flex-grow-1 flex-column justify-evenly items-center v-100">
        <CircularProgress />
      </Box>
    )
  }

  const { totalItems } = data.getRecipes.pagination
  
  return (
    <Container className="Recipes">
      <AppBar id="Recipes header" className="Header pa3" position="fixed">
        <Header
          search={{
            value: decodeURIComponent(search || ''),
            searchAction: handleSearch,
            clearAction: handleClear,
          }}
          filters={{
            value: parsedFilters,
            filterAction: handleFilter
          }}
        />
      </AppBar>
      <InfiniteScroll
        className="List"
        dataLength={recipes.length}
        next={handleMorePages}
        hasMore={recipes.length < totalItems}
        loader={
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '4px' }}>
            <Skeleton variant="rectangular" sx={{ gridColumnEnd: 'span 1', gridRowEnd: 'span 1' }} height={150} />
            <Skeleton variant="rectangular" sx={{ gridColumnEnd: 'span 1', gridRowEnd: 'span 1' }} height={150} />
          </div>
        }
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>{t('Yay! You have seen it all')}</b>
          </p>
        }
      >
        <ImageList>
          {recipes.length > 0 && recipes.map((recipe, i) => (
            <ImageListItem key={recipe.id} onClick={() => {
              store.dispatch(setScroll({ scrollTop }))
              route.history.push(getRoute(recipeRoute, { params: { id: recipe.id }}))
            }}>
              <img
                style={{ height: '150px' }}
                src={`${recipe.cover?.url || EMPTY_STATES[i % PAGE_SIZE]}`}
                // srcSet={`${recipe.cover?.url || EMPTY_STATES[i % PAGE_SIZE]}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={recipe.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={recipe.title}
                subtitle={`${recipe.category.name}`}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </InfiniteScroll>
    </Container>
  );
}