import React from 'react';
import { useQuery } from '@apollo/client';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { GET_RECIPE_CATEGORIES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';

export default function RecipeCategorySelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_RECIPE_CATEGORIES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getRecipeCategories: categories } = data;
  return (
    <FormControl sx={{ my: 1, width: '100%' }} required={required}>
      <InputLabel id="glass-type-selector-label">{t('Category')}</InputLabel>
      <NativeSelect
          id="category-selector"
          defaultValue={defaultValue}
          onChange={onChange}
          input={<OutlinedInput type="select" required={required} />}
         >
          <option hidden value=""></option>
          {categories && categories.map((category, index) =>
            <option key={index} value={category.id}>{category.name}</option>
          )}
      </NativeSelect>
    </FormControl>
  )
}