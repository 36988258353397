import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoIcon from '@mui/icons-material/Info';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { update } from 'serviceWorker';
import { getVersionInfo } from 'utils';
import { Typography } from '@mui/material';

export default function VersionDialog({ opened, toggle, swUpdateAvailable }) {

  const [updating, setUpdating] = useState(false)

  const handleClose = () => {
    toggle(false)
  };

  const handleUpdate = () => {
    setUpdating(true)
    update()
  }

  const info = getVersionInfo()

  return (
    <div>
      <Dialog
        open={opened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Version Info')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="flex items-center" sx={{ marginBottom: '1rem' }}>
            <InfoIcon color="primary" />
            <Typography variant="body1" component="span" sx={{ marginLeft: '1rem' }}>{`v${info.version}`}</Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" className="flex items-center" sx={{ marginBottom: '1rem' }}>
            <ForkRightIcon color="primary" />
            <Typography variant="body1" component="span" sx={{ marginLeft: '1rem' }}>{`${info.branch}:${info.hash}`}</Typography>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" className="flex items-center" sx={{ marginBottom: '1rem' }}>
            <FingerprintIcon color="primary" />
            <Typography variant="body1" component="span" sx={{ marginLeft: '1rem', wordBreak: 'break-all' }}>{`md5:${info.checksum}`}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { swUpdateAvailable && <LoadingButton color="primary" variant="contained" loading={updating} onClick={handleUpdate}>{t('UPDATE')}</LoadingButton> }
          <LoadingButton onClick={handleClose}>{t('CLOSE')}</LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
